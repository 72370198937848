import React, { useState } from 'react';
import { StyledBurger, Nav, NavList, NavItem, NavLink } from './HamburgerMenu.styles.js';

export const HamburgerMenu = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    }

    return (
        <>
            <div>
                <StyledBurger onClick={toggleMenu}/>
                <Nav isOpen={isOpen}>           
                    <NavList>
                        <NavItem><NavLink href="#welcome">Home</NavLink></NavItem>
                        <NavItem><NavLink href="#about">About Us</NavLink></NavItem>
                        <NavItem><NavLink a href="#blog">Blog</NavLink></NavItem>
                        <NavItem><NavLink a href="#table">Comparison</NavLink></NavItem>
                    </NavList>
                </Nav>
            </div>
        </>
    )
}

