import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { handleSummaryClickLogic } from '../../utils/utils.js';
import { SummaryButton } from '../SummaryButton/SummaryButton';
import { Button } from '../../ui/Button/Button.js';
import { LoanCostInfo, LoanQuestion, LoanInstruction, LoanResult, LoanResultTotal, ButtonContainer, Slider, Value } from './LoanCost.styles';
import { typography } from '../../styles/mixins';


// import { Link } from 'react-router-dom'; 

export const LoanCost = ({ selectedBrand, selectedModel, getFuelType, getYearOfCar, monthlyDepreciation, higherPrice, lowerPrice, userWantsLoan, userWantsInsurance, insuranceCost, isFirstVisitToResults, setIsFirstVisitToResults, selectedCars, setSelectedCars, selectedYearPrice, selectedOwnershipYears }) => {

    const navigate = useNavigate();
    const location = useLocation();
    const [percentageAmount, setPercentageAmount] = useState('');
    const [showNextPart, setShowNextPart] = useState(false);
    const [userSubmittedLoan, setUserSubmittedLoan] = useState(false);
    const [calculatedLoanCost, setCalculatedLoanCost] = useState(null);
    const [totalInterestPaid, setTotalInterestPaid] = useState(null);
    const [maxLoanAmount, setMaxLoanAmount] = useState(null);
    const [loanAmount, setLoanAmount] = useState('');

    const formatNumberWithSpaces = (number) => {
        return Math.round(number).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    };


    const parseNumberWithSpaces = (formattedNumber) => {
        return parseInt(formattedNumber.replace(/ /g, ''), 10);
    };

    const handleNextQuestion = () => {
        if (loanAmount !== '') {
            setShowNextPart(true);
        }
    }

    useEffect(()=> {
        if (selectedYearPrice) {
            console.log("selectedYearPrice updated:", selectedYearPrice);
            const maxLoan = selectedYearPrice * 0.8;
            setMaxLoanAmount(maxLoan);
            setLoanAmount(formatNumberWithSpaces(selectedYearPrice * 0.5))

        }
    }, [selectedYearPrice]);


    const handleCalculateLoan = () => {
        const loanAmountValue = parseNumberWithSpaces(loanAmount);
    
        if (!isNaN(loanAmountValue)) {
            const interestRate = 0.0795; // 7.95% annual interest rate
    
            const halfValue = selectedYearPrice * 0.5;

            const percentageBorrowed = loanAmountValue;

            const averageBalance = ((percentageBorrowed + halfValue) / 2);

            const annualInterest = averageBalance * interestRate;

            const monthlyInterestPayment = annualInterest /12;

            const totalInterest = annualInterest * selectedOwnershipYears;
    
            // Set the calculated loan cost (monthly interest payment only)
            setCalculatedLoanCost(monthlyInterestPayment);
            setTotalInterestPaid(totalInterest);
            // Mark that the user has submitted the loan information
            setUserSubmittedLoan(true);
        } else {
            alert('Ange giltiga värden för lånebelopp');
        }
    };
    

    // For an annuity loan
    // Sept 2024 begin
    // const handleCalculateLoan = () => {
    //     // Perform the annuity loan cost calculation based on the loanAmount state
    
    //     const loanAmountValue = parseNumberWithSpaces(loanAmount);
    //     // const percentageValue = parseFloat(percentageAmount);
    
    //     if (!isNaN(loanAmountValue))  {
    //         // Calculate the down payment amount
    //         // const downPayment = (percentageValue / 100) * loanAmountValue;
    //         const downPayment = loanAmountValue * 0.5;
    //         // Calculate the loan amount after down payment
    //         const loanAmountAfterDownPayment = loanAmountValue - downPayment;
    
    //         // Calculate the annuity loan cost
    //         const interestRate = 0.0795; // 7.95%
    //         const loanPeriodMonths = 36 // Three years
    
    //         // Calculate the monthly interest rate
    //         const monthlyInterestRate = interestRate / 12;
    
    //         // Calculate the annuity factor
    //         const annuityFactor = (monthlyInterestRate * Math.pow(1 + monthlyInterestRate, loanPeriodMonths)) / (Math.pow(1 + monthlyInterestRate, loanPeriodMonths) - 1);
    
    //         // Calculate the monthly cost (annuity)
    //         const monthlyCost = loanAmountAfterDownPayment * annuityFactor;
    
    //         // Set the calculated loan cost in the state
    //         setCalculatedLoanCost(monthlyCost);
    
    //         // Mark that the user has submitted the loan information
    //         setUserSubmittedLoan(true);
    //     } else {
    //         alert('Ange giltiga värden för lånebelopp')
    //     }
    // }
    // Sept 2024 end 

    // For an amortizing/standard loan, where monthly payment is calculated to cover both principal and interest but the interest decreases over time

    // const handleCalculateLoan = () => {
    // // Perform the loan cost caluclation based on the loanAmount state

    // const loanAmountValue = parseFloat(loanAmount);
    // const percentageValue = parseFloat(percentageAmount);

    // if (!isNaN(loanAmountValue) && !isNaN(percentageValue) && percentageValue >= 0 && percentageValue <= 100) {
    //     // Calculate the down payment amount
    //     const downPayment = (percentageValue / 100) * loanAmountValue;

    //     // Calculate the loan amount after down payment
    //     const loanAmountAfterDownPayment = loanAmountValue - downPayment;

    //     // Calculate the loan cost
    //     const interestRate = 0.0795; // 7.95%
    //     const loanPeriodMonths = 36 // Three years
    //     const monthlyInterest = loanAmountValue * interestRate / loanPeriodMonths;
    //     const monthlyCost = loanAmountAfterDownPayment / loanPeriodMonths + monthlyInterest;
        
    //     // Set the calculated loan cost in the state
    //     setCalculatedLoanCost(monthlyCost);

    //     // Mark that the user has submitted the loan information
    //     setUserSubmittedLoan(true);
    // } else {
    //     alert('Please provide valid values for loan amount and percentage (0 to 100).')
    //     }

    // }
  const loanSectionRef = useRef(null);
  const summarySectionRef = useRef(null);

  const scrollToSection = (sectionRef) => {
    if (sectionRef.current) {
    console.log(`Scrolling to section: `, sectionRef);
    sectionRef.current.scrollIntoView({ behavior: 'smooth'});
  } else {
    console.log('Failed to scroll. Invalid sectionRef:', sectionRef);
  };
}

setTimeout(() => {
  window.scrollBy(0, 150); 
}, 300); 


useEffect(() => {
    if (userSubmittedLoan && summarySectionRef.current) {
      scrollToSection(summarySectionRef);
    }
  }, [userSubmittedLoan]);
  

    const handleSummaryClick = () => {
        const newData = {
          selectedBrand,
          selectedModel, 
        //   fuelType: selectedFuelType,
          fuelType: getFuelType(), //C
          yearOfCar: getYearOfCar(), //C
          monthlyDepreciation,
          higherPrice,
          lowerPrice,
          userWantsLoan,
          userWantsInsurance,
          insuranceCost,
        //   setIsFirstVisitToResults,
          isFirstVisitToResults: setIsFirstVisitToResults(),
          calculatedLoanCost,
          selectedOwnershipYears
        };
      
        console.log('calculatedLoanCost in LoanCost:', calculatedLoanCost);
        console.log("getFuelType in LoanCost:", getFuelType());
        console.log("setIsFirstVisitToResults in LoanCost:", setIsFirstVisitToResults);
        console.log('Logging from LoanCost handleSummaryClick:', newData);


        handleSummaryClickLogic(navigate, setSelectedCars, selectedCars, newData, newData.calculatedLoanCost);
    };

    const calculatePercentageBorrowed = () => {
        const loanAmountValue = parseNumberWithSpaces(loanAmount);
        if (maxLoanAmount && loanAmountValue) {
      
        const percentageBorrowed = (loanAmountValue / maxLoanAmount) * 80

        return Math.round(percentageBorrowed);
    }
    return 0;
    }
    
    const stepSize = selectedYearPrice * 0.01;


    return (
        <>
            <LoanQuestion div ref={loanSectionRef}>Hur mycket vill du låna?</LoanQuestion>
            <LoanInstruction>Flytta reglaget för att beräkna ett lån på upp till 80 % av bilens nuvarande värde. <br /><br />Kostnaden är baserad på antagandet att du vill betala av 50% vid återförsäljning.</LoanInstruction>
            <form>
                <label>
                    <div >
                
                    <Slider

                        type="range"
                        min="0"
                        max={maxLoanAmount || 100000}
                        step={stepSize}
                        value={parseNumberWithSpaces(loanAmount) || 50000}
                        onChange={(e) => setLoanAmount(formatNumberWithSpaces(e.target.value))}
                        // onBlur={handleNextQuestion}

                    />
                    <Value>{loanAmount} SEK ({calculatePercentageBorrowed()}%)</Value>
                    </div>
                </label>
                <ButtonContainer style={{ marginTop: '2.5rem'}}>
                            <Button type="button" onClick = { () => {
                                handleCalculateLoan();
                                scrollToSection(summarySectionRef);
                                }}
                            >Beräkna</Button>
                </ButtonContainer>
            </form>

             {userSubmittedLoan && calculatedLoanCost !== null && (
                
                <div div ref={summarySectionRef}>
                    <LoanResult>+ Genomsnittlig ränta ({formatNumberWithSpaces(calculatedLoanCost.toFixed(2))} SEK / månad)</LoanResult>
                    <LoanResultTotal>Total betald ränta över {selectedOwnershipYears} år: <br/> {formatNumberWithSpaces(totalInterestPaid.toFixed(2))} SEK</LoanResultTotal>
 
                     <ButtonContainer style={{ marginTop: '2.5rem'}}>
                        <SummaryButton 
                            type="button"
                            onClick={handleSummaryClick}
                            selectedBrand={selectedBrand}
                            selectedModel={selectedModel}
                            // fuelType={getFuelType}
                            getFuelType={getFuelType()}
                            getYearOfCar={getYearOfCar()}
                            monthlyDepreciation={monthlyDepreciation}
                            // higherPrice={higherPrice}
                            // lowerPrice={lowerPrice}
                            userWantsLoan={userWantsLoan}
                            calculatedLoanCost={calculatedLoanCost} 
                            userWantsInsurance={userWantsInsurance}
                            isFirstVisitToResults={isFirstVisitToResults}
                            setIsFirstVisitToResults={setIsFirstVisitToResults}
                            selectedCars={selectedCars}
                            setSelectedCars={setSelectedCars}
                            selectedOwnershipYears={selectedOwnershipYears}
                            primary={"primary"}
                            >Visa sammanfattning!</SummaryButton>
                    </ButtonContainer>
                </div>
            )} 
        </>
              
  
    )
}