// import logo from './logo.svg';
// import './App.css';
// import { Provider } from 'react-redux'
import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom'
// import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { NavBar } from './components/NavBar/NavBar'
import { WelcomePage } from './components/WelcomePage/WelcomePage'
import { AboutUs } from './components/AboutUs/AboutUs'
import { ComparisonPage } from './components/ComparisonPage/ComparisonPage'
import { ResultsPage } from './components/ResultsPage/ResultsPage'
import { SummaryPage } from './components/SummaryPage/SummaryPage'
import { ComparisonModeProvider } from './context/ComparisonModeContext'
import { BackToTop } from './ui/BackToTop/BackToTop'

export const  App = () => {

  // const reducer = combineReducers({
  //   user: user.reducer,
  //   events: events.reducer
  // });

  // const store = configureStore({ reducer })

  // const location = useLocation();

  // useEffect(() => {
  //   window.gtag('config', 'G-K2J3BVJM3F', {
  //     page_path: location.pathname,
  //   });
  // }, [location]);
  // console.log(window.gtag);


  return (
    // <Provider store={store}>
    <ComparisonModeProvider>
       <BrowserRouter basename={process.env.PUBLIC_URL}>
       <NavBar />
       <Routes>
        <Route path="/" element={<WelcomePage />} />
        <Route path="/comparison" element={<ComparisonPage />} />
        <Route path="/results" element={<ResultsPage />} />
        <Route path="/summary" element={<SummaryPage />} />
        <Route path="/aboutus" element={<AboutUs />} />
        {/* <Route path="*" element={<NotFound />} /> */}
      </Routes>
      <BackToTop/>
      </BrowserRouter>
    {/* // </Provider> */}
    </ComparisonModeProvider>
      
        
    // <div className="App">
    //   <header className="App-header">
    //     <img src={logo} className="App-logo" alt="logo" />
    //     <p>
    //       Edit <code>src/App.js</code> and save to reload.
    //     </p>
    //     <a
    //       className="App-link"
    //       href="https://reactjs.org"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       Learn React
    //     </a>
    //   </header>
    // </div>
  );
}

export default App;
