import React, { createContext, useContext, useState } from 'react';

const ComparisonModeContext = createContext();

export const useComparisonMode = () => {
    return useContext(ComparisonModeContext);
}

export const ComparisonModeProvider = ({ children }) => {
    const [isComparisonMode, setIsComparisonMode] = useState(false);
    const [comparisonData, setComparisonData] = useState([]); // new state for comparison data 
    const [isFirstVisitToResults, setIsFirstVisitToResults] = useState(true);
    const [selectedCars, setSelectedCars] = useState([]); 
    
    const [higherPrice, setHigherPrice] = useState(0);
    const [lowerPrice, setLowerPrice] = useState(0);

  
    const [ownershipYears, setOwnershipYears] = useState([1, 2, 3, 4, 5]);
    const [selectedOwnershipYears, setSelectedOwnershipYears] = useState('');


    // Function to add car data to the comparison array 
    const addToComparison = (carData) => {
        setComparisonData([...comparisonData, carData]);


    // New function to remove car data from the comparison array 
    // const removeFromComparison = (carData) => {
    //     const updatedData = comparisonData.filter((data) => data !== carData);
    //     setComparisonData(updatedData);
    }

    const contextValue = {
        isComparisonMode,
        setIsComparisonMode,
        isFirstVisitToResults, // Include isFirstVisitToResults in the context
        setIsFirstVisitToResults, // Also include the setter function
        comparisonData,
        addToComparison,
        selectedCars,
        setSelectedCars,
        ownershipYears,
        setOwnershipYears,
        selectedOwnershipYears,
        setSelectedOwnershipYears,
        higherPrice,
        setHigherPrice,
        lowerPrice,
        setLowerPrice
      };
    
    return (
        <ComparisonModeContext.Provider value={contextValue}>
            {children}
        </ComparisonModeContext.Provider>
    )
}