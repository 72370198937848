import React from 'react';
import { StyledLabel, DropdownMenuWrapper, SelectValue, Option } from './DropdownMenu.styles';


export const DropdownMenu = ({ label, options, selectedOption, onChange, sortType, placeholder = "Välj ett alternativ" }) => {

  const sortedOptions = sortType === 'numerical'
  ? options.slice().sort((a, b) => b - a)
  : options;

  // const handleModelSelection = (event) => {
  //   const selectedModel = event.target.value;
  //   console.log("Selected Model DD:", selectedModel);
  //   onChange(event.target.value); // updated selectedModel with the chosen car model
  // };

  const handleSelection = (event) => {
    const selectedValue = event.target.value;
    console.log("Selected Value:", selectedValue); // For debugging
    onChange(selectedValue); // Pass the selected value to the onChange handler
  };

 
  return (
    <DropdownMenuWrapper>
      <StyledLabel>{label}</StyledLabel>
      {/* <select value={selectedOption} onChange={(e) => onChange(e.target.value)}> */}
      <SelectValue value={selectedOption} onChange={handleSelection}>
        <Option value="">{placeholder}</Option>
            {sortedOptions.map((option, index) => ( 
            <Option key={index} value={option}>
                {option}
            </Option>
            ))}
      </SelectValue>
    </DropdownMenuWrapper>
  );
};
