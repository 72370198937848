import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useComparisonMode } from '../../context/ComparisonModeContext';
import { CompareButton } from '../CompareButton/CompareButton';
import { Wrapper } from '../Wrapper/Wrapper';
import { SummaryPageTitle, SelectedCarSummaryTitle, ExpectedWorthToday, ExpectedWorthInFuture, InformationAnnouncement, SummaryStats, Stat, ButtonContainer, SummaryTotal, SummaryTotalResultTitle, SummaryTotalResultContainer, SummaryTotalResult } from './SummaryPage.styles';
import { ReactComponent as Graph } from '../../assets/Graph.svg';
import { ReactComponent as Umbrella } from '../../assets/Umbrella.svg';
import { ReactComponent as Money } from '../../assets/Money.svg';
import { Icon } from '../../ui/Icon/Icon';
import { Button } from '../../ui/Button/Button';


export const SummaryPage = ({ getFuelType, getYearOfCar, isFirstVisitToResults, setIsFirstVisitToResults, userWantsInsurance, userWantsLoan}) => {

  console.log("userWantsLoan in Summary Page", userWantsLoan)
  console.log("userWantsInsurance in Summary Page", userWantsInsurance)
  const navigate = useNavigate();
  const location = useLocation();
  // const { comparisonData } = location.state || {};
  // const state = location.state;
  //   const { calculatedLoanCost, selectedFuelType, selectedBrand, selectedModel, selectedYearOfCar } = location.state || {};
  const { selectedOwnershipYears, higherPrice, lowerPrice } = useComparisonMode();

  const { selectedCars } = location.state || {};
    // calculatedLoanCost, monthlyDepreciation, selectedBrand, selectedModel, getYearOfCar, getFuelType, userWantsLoan, userWantsInsurance,

  // const [userWantsLoan, setUserWantsLoan] = useState(false);
  // const [userWantsInsurance, setUserWantsInsurance] = useState(false);

  const handleBackToStart = () => {
    navigate("/");
  }
  console.log("Selected Cars in SummaryPage:", selectedCars);
  console.log("Selected Cars length in SummaryPage:", selectedCars.length); 
  console.log('selectedOwnershipYears in SummaryPage:', selectedOwnershipYears)

  // console.log("getFuelType in SummaryPage:", getFuelType()); //?
  // console.log("getFuelType in SummaryPage:", selectedCars.fuelType); //?
  // console.log("getFuelType , car, in SummaryPage:", selectedCars.car.fuelType); //?
  // console.log("getFuelType in Summary Page", getFuelType);


  const formatNumberWithSpaces = (number) => {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  };

  // const monthlyCost = car.monthlyDepreciation + car.calculatedLoanCost;
  // const yearlyCost = monthlyCost * 12;

    return (
      <>
        <Wrapper>
          <SummaryPageTitle>Sammanfattning</SummaryPageTitle>
          {selectedCars && selectedCars.map((car, index) => (
            // ADD INSURANCE COST || NULL TO KEY AS WELL 
          // <div key={`${car.selectedModel}-${car.yearOfCar}-${car.fuelType}-${car.monthlyDepreciation}-${car.calculatedLoanCost || 'noLoanCost'}-${car.selectedOwnershipYears}-${index}`}>
          <div key={`${car.selectedModel}-${car.yearOfCar}-${car.fuelType}-${car.monthlyDepreciation}-${car.higherPrice}-${car.lowerPrice}-${car.calculatedLoanCost || 'noLoanCost'}-${car.insuranceCost || 'noInsuranceCost'}-${car.selectedOwnershipYears}-${index}`}>

              
              {/* <h2>Car {index + 1}:</h2> */}
              {console.log(`Summary: Car ${index+ 1} Details:`, car)}
              {console.log("Current Car in SummaryPage:", car)}
              {console.log("getFuelType in Summary Page", car.fuelType)}
              {console.log("Current car higherPrice:", car.higherPrice)}
              {console.log("Current car lowerPrice:", car.lowerPrice)}
             

              {/* <p>Brand: {car.selectedBrand}</p>
              <p>Model: {car.selectedModel}</p>
              <p>FuelType: {car.fuelType}</p>
              <p>Year: {car.yearOfCar}</p>
              <p>CLC: {car.calculatedLoanCost}</p>
              <p>Insurance: {car.insuranceCost} per month</p> */}


              {/* Cost-related content */}
              <SelectedCarSummaryTitle> {car.selectedBrand} {car.selectedModel} {car.fuelType} - {car.yearOfCar}</SelectedCarSummaryTitle>

              {/* {car.monthlyDepreciation !== null && (
                <h3>Total värdeminskning över 3 år: {`${car.monthlyDepreciation.toFixed(2)}`} SEK/month.</h3>
              )} */}

              {/* {car.userWantsLoan && car.userWantsInsurance && (
                <>
                  <h3>The cost of your loan over three years will be {`${car.calculatedLoanCost.toFixed(2)}`} SEK/month.</h3>
                  <h3>The cost of your insurance over three years will be {`${car.insuranceCost}`} SEK/month </h3>
                </>
              )}

              {car.userWantsLoan && !car.userWantsInsurance && (
                <>
                  <h3>The cost of your loan over three years will be {`${car.calculatedLoanCost.toFixed(2)}`} SEK/month.</h3>
                  <h3>You opted not to add insurance costs.</h3>
                </>
              )}

              {!car.userWantsLoan && car.userWantsInsurance && (
                <>
                  <h3>The cost of your insurance over three years will be {`${car.insuranceCost}`} SEK/year/month????.</h3>
                  <h3>You opted not to add a loan.</h3>
                </>
              )}

              {!car.userWantsLoan && !car.userWantsInsurance && (
                <>
                  <h3>You opted not to add insurance costs.</h3>
                  <h3>You opted not to add a loan.</h3>
                </>
              )} */}
              <ExpectedWorthToday>Uppskattad värdering idag: {`${car.higherPrice}`}</ExpectedWorthToday>
              <ExpectedWorthInFuture>Uppskattad värdering om {`${car.selectedOwnershipYears}`} år: {`${car.lowerPrice}`} </ExpectedWorthInFuture>
              <InformationAnnouncement>Uppskattad totalkostnad över <span style={{ color: "var(--color-primary)" }}>{car.selectedOwnershipYears} år:</span></InformationAnnouncement>
              <SummaryStats>
                <Stat><Icon as={Graph}/>Värdeminskning: <span>{`${ formatNumberWithSpaces(Math.round(car.monthlyDepreciation))}`} SEK/månad</span></Stat>
                {/* <Stat><Icon as={Graph}/> Värdeminskning: {`${roundAndFormatNumber(car.monthlyDepreciation)} SEK/m`}</Stat> */}

                  {!car.userWantsLoan && !car.userWantsInsurance && (
                    <>
                      <Stat style={{ color: 'var(--color-text-disabled'}}><Icon as={Umbrella}/>Du valde att INTE lägga till försäkring</Stat>
                      <Stat style={{ color: 'var(--color-text-disabled'}}><Icon as={Money}/>Du valde att INTE lägga till ett lån</Stat>
                      <SummaryTotal>
                      <SummaryTotalResultTitle>Min bilkostnad total:</SummaryTotalResultTitle>
                      {/* <SummaryTotalResult>{car.monthlyDepreciation} SEK / månad,</SummaryTotalResult>  */}
                      <SummaryTotalResult>{formatNumberWithSpaces(Math.round(car.monthlyDepreciation))} SEK / månad</SummaryTotalResult>
                      <SummaryTotalResult><span>=</span></SummaryTotalResult>
                      {/* <SummaryTotalResult>{(car.monthlyDepreciation * 12)} SEK / år</SummaryTotalResult> */}
                      <SummaryTotalResult>{formatNumberWithSpaces(Math.round(car.monthlyDepreciation * 12))} SEK / år</SummaryTotalResult>

                      </SummaryTotal>
                      {/* <button type="button" onClick={handleBackToStart}>Start again</button> */}
                    </>
                  )}

                {(car.userWantsLoan || car.userWantsInsurance) && (
                  <div>
                    {car.userWantsLoan && !car.userWantsInsurance && (
                      <div>
                        {/* <Stat><Icon as={Money}/> Lån: {`${car.calculatedLoanCost.toFixed(2)}`} SEK/m</Stat> */}
                        <Stat style={{ color: 'var(--color-text-disabled'}}><Icon as={Umbrella}/>Du valde att INTE lägga till försäkring</Stat>
                        <Stat><Icon as={Money}/> Lån: <span>{`${ formatNumberWithSpaces(Math.round(car.calculatedLoanCost))} SEK/månad`}</span></Stat>
                      </div>
                    )}
                    {car.userWantsInsurance && !car.userWantsLoan && (
                      // This is a placeholder for the value
                      <div>
                        {/* <Stat><Icon as={Umbrella}/> Försäkring: {`${car.insuranceCost.toFixed(2)}`} SEK/m</Stat> */}
                        <Stat><Icon as={Umbrella}/> Försäkring: <span>{`${ formatNumberWithSpaces(Math.round(car.insuranceCost))} SEK/månad`}</span></Stat>
                        <Stat style={{ color: 'var(--color-text-disabled'}}><Icon as={Money}/>Du valde att INTE lägga till ett lån</Stat>
                      </div>
                    )}
                    {car.userWantsLoan && car.userWantsInsurance && (
                      <div>
                      {/* <Stat><Icon as={Umbrella}/> Försäkring: {`${car.insuranceCost.toFixed(2)}`} SEK/m</Stat> */}
                      <Stat><Icon as={Umbrella}/> Försäkring: <span>{`${formatNumberWithSpaces(Math.round(car.insuranceCost))} SEK/månad`}</span></Stat>
                      {/* <Stat><Icon as={Money}/> Lån: {`${car.calculatedLoanCost.toFixed(2)}`} SEK/m</Stat> */}
                      <Stat><Icon as={Money}/> Lån: <span>{`${formatNumberWithSpaces(Math.round(car.calculatedLoanCost))} SEK/månad`}</span></Stat>
                    </div>
                    )
                    }
                    <SummaryTotal>
                      <SummaryTotalResultTitle>Min bilkostnad total:</SummaryTotalResultTitle>
                      <SummaryTotalResultContainer>
                        {/* <SummaryTotalResult>{(car.monthlyDepreciation + car.calculatedLoanCost)} SEK / m </SummaryTotalResult> */}
                        <SummaryTotalResult>{formatNumberWithSpaces(Math.round(car.monthlyDepreciation + (car.userWantsLoan ? car.calculatedLoanCost : 0) + (car.userWantsInsurance ? car.insuranceCost : 0 )))} SEK / månad</SummaryTotalResult> 
                        <SummaryTotalResult><span>=</span></SummaryTotalResult>
                        {/* <SummaryTotalResult>{((car.monthlyDepreciation + car.calculatedLoanCost) * 12)} SEK / å</SummaryTotalResult> */}
                        <SummaryTotalResult>{formatNumberWithSpaces(Math.round((car.monthlyDepreciation + (car.userWantsLoan ? car.calculatedLoanCost : 0) + (car.userWantsInsurance ? car.insuranceCost : 0)) * 12))} SEK / år</SummaryTotalResult>
                      </SummaryTotalResultContainer>

                    </SummaryTotal>

                  </div>

                )}
              </SummaryStats>

            </div>
          ))}
          {console.log("getFuelType before rendering CompareButton in Summary Page:", getFuelType)}
          {console.log("setIsFirstVisitToResults present in Summary", setIsFirstVisitToResults)}
          <ButtonContainer>
             <CompareButton
                  // selectedBrand={selectedBrand}
                  // selectedModel={selectedModel}
                  // onClick={handleCompareClick}
                  // getFuelType={getFuelType()}
                  // getFuelType={getFuelType}
                  // getYearOfCar={getYearOfCar}

                  // getYearOfCar={getYearOfCar()}
                  // selectedFuelType={selectedFuelType}
                  // getFuelType={selectedFuelType}
                  
                  // monthlyDepreciation={monthlyDepreciation}
                  // userWantsLoan={userWantsLoan}
                  // userWantsInsurance={userWantsInsurance}
                  isFirstVisitToResults={isFirstVisitToResults}
                  setIsFirstVisitToResults={setIsFirstVisitToResults}
                  // setIsComparisonMode={setIsComparisonMode}
                  // setSelectedCars={setSelectedCars}
                  selectedCars={selectedCars}
                  navigate={navigate}
                  location={location}
                />
                
              <Button type="button" onClick={handleBackToStart} secondary={"true"}>Börja om</Button>
            </ButtonContainer>
          </Wrapper>

        </>
    )}


