// First utility function for ResultsPage

export const handleSummaryClickLogic = (navigate, setSelectedCars, selectedCars, newData, calculatedLoanCost) => {
    console.log('Logging from handleSummaryClickLogic:', newData);

    const lastCarIndex = selectedCars.length - 1;
    const updatedCars = [...selectedCars];
    updatedCars[lastCarIndex] = { ...newData, calculatedLoanCost };
  
    console.log('Calculated Loan Cost in handleSummaryClickLogic:', calculatedLoanCost);

    navigate("/summary", {
      state: {
        selectedCars: updatedCars
      }
    });
  
    console.log("Monthly Depreciation for each car:", updatedCars.map(car => car.monthlyDepreciation));
    console.log("Calculated Loan Cost for each car:", updatedCars.map(car => car.calculatedLoanCost));
    console.log("Updated cars:", updatedCars);

    setSelectedCars(updatedCars);
  };

  // utils.js
// export const handleSummaryClickLogic = (navigate, setSelectedCars, selectedCars, newData) => {
//     const lastCarIndex = selectedCars.length - 1;
//     const updatedCars = [...selectedCars];
//     updatedCars[lastCarIndex] = { ...newData };

//     console.log('Calculated Loan Cost in handleSummaryClickLogic:', newData.calculatedLoanCost);

//     // Exclude any functions from the state
//     const stateWithoutFunctions = {
//         selectedCars: updatedCars.map(car => ({ ...car, calculatedLoanCost: undefined })),
//     };

//     navigate("/summary", {
//         state: stateWithoutFunctions,
//     });

//     console.log("Monthly Depreciation for each car:", updatedCars.map(car => car.monthlyDepreciation));
//     console.log("Calculated Loan Cost for each car:", updatedCars.map(car => car.calculatedLoanCost));
//     console.log("Updated cars:", updatedCars);

//     setSelectedCars(updatedCars);
// };

// export const handleSummaryClickLogic = (navigate, setSelectedCars, selectedCars, newData) => {
//     const lastCarIndex = selectedCars.length - 1;
//     const updatedCars = [...selectedCars];
//     updatedCars[lastCarIndex] = { ...newData };

//     console.log('Calculated Loan Cost in handleSummaryClickLogic:', newData.calculatedLoanCost);

//     // Exclude any functions from the state
//     const stateWithoutFunctions = {
//         selectedCars: updatedCars.map(car => ({ ...car, calculatedLoanCost: undefined })),
//     };

//     // Use history API directly to push state without cloning issues
//     navigate("/summary");
//     window.history.replaceState(stateWithoutFunctions, ''); 

//     console.log("Monthly Depreciation for each car:", updatedCars.map(car => car.monthlyDepreciation));
//     console.log("Calculated Loan Cost for each car:", updatedCars.map(car => car.calculatedLoanCost));
//     console.log("Updated cars:", updatedCars);

//     setSelectedCars(updatedCars);
// };


  // Seocond utility function for CompareButton
  export const isFromResultsPage = (location) => {
    // Implement the logic to check if it's from the Results page based on the location or any other condition
    // For example, you might check the pathname or state in the location object
    return location.pathname === "/results";
  };