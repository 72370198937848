import styled from 'styled-components';
import { typography } from '../../styles/mixins';

export const ResultsTitle = styled.h1`
    color: var(--color-neutral-4-black);
    display: flex;
    align-self: flex-start;
    max-width: 100%;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    ${typography('h3', 'header')};

    @media (min-width: 744px) {
        ${typography('h2')};
        align-self: center;
        padding-top: 1rem;
        padding-bottom: 1rem;
    }

    @media (min-width: 1440px) {
        ${typography('h1')};
        padding-top: 1.375rem;
        padding-bottom: 1.375rem;
    }
`

export const SelectedCarContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 2px solid black;
    
`

export const SelectedCarYear = styled.h2`
    color: var(--color-neutral-1-black);
    display: flex;
    padding-top: 2.5rem;
    ${typography('h5', 'header')};
    font-weight: normal;

    @media (min-width: 744px) {
        flex-direction: row;
        justify-content: space-around;
        ${typography('h3')};
        /* align-self: center; */
        padding-top: 1rem;
        padding-bottom: 1rem;
        padding-left: 3rem;
        font-weight: normal;

    }

    @media (min-width: 1440px) {
        ${typography('h2')};
        padding-top: 1.375rem;
        padding-bottom: 1.375rem;
        font-weight: normal;


    }

`
export const SelectedCar = styled.h2`
    color: var(--color-primary);
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    /* max-width: 100%; */
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
   
    ${typography('h4', 'header')};
    font-weight: bold;
    /* width: 100%; */

    @media (min-width: 744px) {
        flex-direction: row;
        justify-content: space-around;
        ${typography('h3')};
        /* align-self: center; */
        padding-top: 1rem;
        padding-bottom: 1rem;
        gap: 1rem;
    }

    @media (min-width: 1440px) {
        ${typography('h2')};
        padding-top: 1.375rem;
        padding-bottom: 1.375rem;
    }
`
export const Question = styled.h3`
    color: var(--color-neutral-4-white);
    background-color: var(--color-neutral-1-black);
    width: 100%;
    padding: 0.5rem;
    margin-top: 3.5rem;
    margin-bottom: 0.5rem;
    border-radius: 0.25rem;
    ${typography('h5', 'header')}
    font-weight: bold;

    @media (min-width: 744px) {
        ${typography('h4')};
        font-weight: bold;
        /* align-self: center; */
     
    }

    @media (min-width: 1440px) {
        ${typography('h3')};
        font-weight: bold;
      
    }
`

export const CompareQuestion = styled.h3`
    color: var(--color-neutral-4-white);
    background-color: var(--color-neutral-1-black);
    width: 100%;
    padding: 0.5rem;
    margin-top: 3.5rem;
    margin-bottom: 0.5rem;
    border-radius: 0.25rem;
    ${typography('h4', 'header')}

    @media (min-width: 744px) {
        ${typography('h2')};
        align-self: center;
        padding-top: 1rem;
        padding-bottom: 1rem;
    }

    @media (min-width: 1440px) {
        ${typography('h1')};
        padding-top: 1.375rem;
        padding-bottom: 1.375rem;
    }

`

export const ButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin-top: 1rem;
    /* margin-bottom: 1rem; */
   
`

export const ButtonContainerInsurance = styled.div`
      display: flex;
      flex-direction: row;
      gap: 1rem;
      width: 100%;
      
`