import React, { useEffect, useState } from 'react';
import { CurrentValuation, ExpectedValuation, DepreciationResult, DepreciationValue } from './DepreciationTable.styles.js';
import { useComparisonMode } from '../../context/ComparisonModeContext.js';

export const DepreciationTable = ({ selectedBrand, selectedModel, getFuelType, getYearOfCar, onMonthlyDepreciationChange, onSelectedYearPriceChange, selectedOwnershipYears  }) => {

    // const [higherPrice, setHigherPrice] = useState(0);
    // const [lowerPrice, setLowerPrice] = useState(0);    
    const { higherPrice, setHigherPrice, lowerPrice, setLowerPrice } = useComparisonMode();
    const [depreciationDifference, setDepreciationDifference] = useState(0);
    const [monthlyDepreciation, setMonthlyDepreciation] = useState(0);
    const selectedModelString = String(selectedModel);
    const selectedYearOfCar = getYearOfCar();
    const selectedFuelType = getFuelType();
    console.log('Selected year of car in DepreciationTable:', selectedYearOfCar); 
    console.log('Selected fuel type in DepreciationTable:', selectedFuelType); 

    const selectedYearOfCarInt = parseInt(selectedYearOfCar, 10);
  
    const formatNumberWithSpaces = (number) => {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    };
console.log('selectedOwnershipYears:', selectedOwnershipYears)

// Fetch available prices for the selected brand, model and year of car 
useEffect(() => {
    if (selectedModel) {
        fetch(`https://minbilkostnad-backend.onrender.com/price?selectedBrand=${encodeURIComponent(selectedBrand)}&selectedModel=${encodeURIComponent(selectedModelString)}&selectedFuelType=${encodeURIComponent(selectedFuelType)}`)
        .then(async (response) => {
            console.log('Response:', response);
            if (!response.ok) {
                console.error('Network response was not ok');
                return [];
            }
            const data = await response.json();
            console.log('data.response:', data.response);
            console.log('Depreciation: selectedYearOfCar:', selectedYearOfCar);

            if (data.success) {
                const selectedYearPrice = data.response.find((item) => item.yearOfCar === selectedYearOfCarInt);
                const threeYearsAgo = selectedYearOfCar -3;
                const threeYearsLower = data.response.find((item) => item.yearOfCar === threeYearsAgo);

                if (selectedYearPrice && threeYearsLower) {
                    const priceDifference = selectedYearPrice.price - threeYearsLower.price;
                    
                    setHigherPrice(Math.round(selectedYearPrice.price));
                    console.log('priceDifference between high and low raw data prices:', priceDifference)
                    
                    // to calculate one year depreciation from the 3 years lower figure  
                    // const annualDepreciation = priceDifference / 3; // THIS IS WRONG

                    console.log('selectedOwnershipYears:', selectedOwnershipYears)

                    // To calculate the amount it will depreciate over those selected years
                    const depreciationOverSelectedYears = priceDifference * (selectedOwnershipYears / 3);

                    console.log('depreciationOverSelectedYears = priceDifference * (years / 3):', depreciationOverSelectedYears)
                    // to account for the possible 1 - 5 years selection, multiply by whatever the user specifed
                    // const lowerPriceSetToSelectedOwnershipYears = annualDepreciation * selectedOwnershipYears; // THIS IS WRONG
                    
                    // To work out the price expectation in selectedOwnershipYears amount of time
                    const lowerPriceSetToSelectedOwnershipYears = selectedYearPrice.price - depreciationOverSelectedYears;
                    console.log('lowerPriceSetToSelectedOwnershipYears (selectedYearPrice - depreciationOverSelectedYears):', lowerPriceSetToSelectedOwnershipYears)                     

                    // Apply the 15% (*0.85) reduction to lowerPriceSetToSelectedOwnershipYears to account for real market behaviour at point of sale 
                    const adjustedLowerPrice = lowerPriceSetToSelectedOwnershipYears * 0.85;
                    console.log('Adjusted lowerPrice (lowerPrice * 0.85)):', adjustedLowerPrice);   
                
                    setLowerPrice(Math.round(adjustedLowerPrice));
                    console.log('lowerPrice after all calculations:', lowerPrice)


                    const depreciationDifference = selectedYearPrice.price - adjustedLowerPrice;
                    setDepreciationDifference(depreciationDifference);

                    // Calculate monthlyDepreciationValue in months, based on selectedOwnershipYears choice
                    const months = selectedOwnershipYears * 12;
                    // const monthlyDepreciationValue = depreciationDifference / months;
                    
                    const monthlyDepreciationValue = depreciationDifference / (months);
                    setMonthlyDepreciation(Math.round(monthlyDepreciationValue));

                    // const adjustedLowerPrice = Math.round(threeYearsLower.price * 0.85);
                


                    // setMonthlyDepreciation(Math.round(priceDifference / 36));
                    // setMonthlyDepreciation(parseFloat(priceDifference / 36).toFixed(2));
                    onSelectedYearPriceChange(selectedYearPrice.price);

                }

                console.log('selectedYearPrice:', selectedYearPrice)
                console.log('three years lower:', threeYearsLower)

                console.log('selected year of car int:', selectedYearOfCarInt)

           
        

            } else {
                console.error('Prices request was not successful; year not found', data);
            }
        })
        .catch((error) => {
            console.error('Error fetching prices:', error);
        })
    }
}, [selectedBrand, selectedModel, selectedFuelType, selectedYearOfCar, selectedYearOfCarInt, selectedOwnershipYears, onSelectedYearPriceChange, selectedModelString]);
    
console.log ('higherPrice:', higherPrice)
console.log ('lowerPrice:', lowerPrice)
console.log('monthyDepreciation in Dep Table:', monthlyDepreciation)

// Call this callback function when monthlyDepreciation changes
useEffect(() => {
    onMonthlyDepreciationChange(monthlyDepreciation);
  }, [monthlyDepreciation, onMonthlyDepreciationChange]);
  
    return (
        <div>            
            <CurrentValuation>Uppskattad värdering idag: <br/><br/> <span>{`${formatNumberWithSpaces(higherPrice)}`} SEK</span> </CurrentValuation>
            <ExpectedValuation>Uppskattad värdering om {`${selectedOwnershipYears}`} år:  <br/><br/> <span>{`${formatNumberWithSpaces(lowerPrice)}`} SEK</span></ExpectedValuation>
            <DepreciationResult>Total värdeminskning över {`${selectedOwnershipYears}`} år:</DepreciationResult> 
            {typeof monthlyDepreciation === 'number' ? (
                <>
                    <DepreciationValue>
                    {formatNumberWithSpaces(Math.round(depreciationDifference))} SEK 
                    ({formatNumberWithSpaces(monthlyDepreciation)} SEK / månad)
                    </DepreciationValue>
                   
                </>
            ) : (
                <p>N/A</p>
            )}
        </div>    
    )
}
   
