import React from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import Car from '../../lotties/CarColoured.json';
import { WrapperLoading, StyledCarLottie, LoadingSign } from './Loading.styles';


export const Loading = () => {
   return (
    <WrapperLoading>
        <StyledCarLottie>
           <Player
               autoplay
               loop
               src={Car}
               speed={0.4}
               // style={{ height: '10.8125rem', width: '17.0625rem' }}
               style={{ 'height': 'var(--height)', 'width': 'var(--width' }}
           >
           </Player>
       </StyledCarLottie>
       <LoadingSign>Var god vänta medan vi hämtar bilarna för dig</LoadingSign>
    </WrapperLoading>

   )
}