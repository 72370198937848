import React, { useState, useEffect } from "react";
import { Button } from '../Button/Button';

export const BackToTop = () => {
    const [isVisible, setIsVisible] = useState(false);

    const toggleVisibility = () => {
        if (window.scrollY > 1050) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    }

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behaviour: "smooth"
        })
    }

    useEffect(() => {
        window.addEventListener("scroll", toggleVisibility);
        return () => {
            window.removeEventListener("scroll", toggleVisibility);
        }
    }, []);


    return (
        <div>
            {isVisible && (
                <Button
                    onClick={scrollToTop}
                    style={styles.backToTopButton}
                >
                ^
                </Button>
            )}
        </div>
    )
}

const styles = {
    backToTopButton: {
      position: "fixed",
      bottom: "2px",
      left: "50%",
      transform: "translateX(-50%)", 
      zIndex: 100,
      backgroundColor: "white",
      color: "green",
      width: "40px",
      height: "40px",
      border: "1px solid green",
      borderRadius: "50%",
      cursor: "pointer",
      fontSize: "16px",
      display: "flex",  // Ensures the content (^) is centered
      justifyContent: "center",
      alignItems: "center"
    }
}