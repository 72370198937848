import React from 'react';
import { Button } from '../../ui/Button/Button'


export const ConfirmationButton = (props) => {
    const { onClick, buttonText } = props;
    // console.log('calculatedLoanCost in ConfirmationButton:', calculatedLoanCost);
    console.log('buttonText prop:', buttonText);
    return (
        <Button type="button" onClick={onClick}>{buttonText}</Button>

    )
}

