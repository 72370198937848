import 'normalize.css';
import { createGlobalStyle } from 'styled-components';


export const GlobalStyles = createGlobalStyle`

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
:root {
    --font-family-paragraph: 'Poppins', sans-serif;
    --font-family-header: 'Montserrat', serif;

    --font-size-h1: 2.375rem;
    --font-size-h2: 2rem;
    --font-size-h3: 1.75rem;
    --font-size-h4: 1.5rem;
    --font-size-h5: 1.375rem;
    --font-size-h6: 1.2rem;
    --font-size-p1: 1.5rem;
    --font-size-p2: 1.375rem;
    --font-size-p3: 1.125rem;
    --font-size-p4: 1rem;
    --font-size-p5: 0.875rem;
    --font-size-p6: 0.75rem;

    --font-weight-h1: 700;
    --font-weight-h2: 700;
    --font-weight-h3: 700;
    --font-weight-h4: 700;
    --font-weight-p1: 500;
    --font-weight-p2: 500;
    --font-weight-p3: 400;
    --font-weight-p4: 400;
    --font-weight-p5: 400;
    --font-weight-p6: 400;



    --line-height-h1: normal;
    --line-height-h2: normal;
    --line-height-h3: normal;
    --line-height-h4: normal;
    --line-height-p1: normal;
    --line-height-p2: normal;
    --line-height-p3: normal;
    --line-height-p4: normal;
    --line-height-p5: normal;
    --line-height-p6: normal;


  }


html {
    font-size: 16px;

}

body {
    font-family: var(--font-family-paragraph), var(--font-family-header), 'Courier New', Courier, monospace, Helvetica, sans-serif;
    font-size: 1rem;
    background-color: var(--color-neutral-4-white);
    margin: 0;
    padding: 0;
}
`