import React, { useState, useEffect, useRef, useCallback, useContext } from 'react';
import { useComparisonMode } from '../../context/ComparisonModeContext';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { DepreciationTable, selectedYearPrice } from '../DepreciationTable/DepreciationTable';
import { InsuranceCost } from '../InsuranceCost/InsuranceCost';
import { LoanCost } from '../LoanCost/LoanCost';
import { handleSummaryClickLogic } from '../../utils/utils.js';
import { CompareButton } from '../CompareButton/CompareButton';
import { SummaryButton } from '../SummaryButton/SummaryButton';
import { Button } from '../../ui/Button/Button';
import { Wrapper } from '../Wrapper/Wrapper';
import { ResultsTitle, SelectedCarContainer, SelectedCar, SelectedCarYear, Question, ButtonContainer, ButtonContainerInsurance, CompareQuestion } from './ResultsPage.styles.js';
import { LoanCostInfo } from '../LoanCost/LoanCost.styles';
import { ReactComponent as Umbrella } from '../../assets/Umbrella.svg';
import { ReactComponent as Money } from '../../assets/Money.svg';
import { Icon } from '../../ui/Icon/Icon';


// import { useHistory } from 'react-router-dom';


export const ResultsPage = ({secondary}) => {


  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state;
  const { selectedBrand, selectedModel } = location.state || {};


  const yearOfCarImplicit = location.state?.selectedBrand?.selectedModel?.yearOfCar?.[0];
  const fuelTypeImplicit = location.state?.selectedBrand?.selectedModel?.fuelType?.[0];

  const selectedYearOfCar = state?.selectedYearOfCar || yearOfCarImplicit;
  const selectedFuelType = state?.selectedFuelType || fuelTypeImplicit;


  // Retrieve from local storage if not found in state
  const storedOwnershipYears = localStorage.getItem('selectedOwnershipYears');

 // Use either state or fallback to the implicit year or the local storage value
 const selectedOwnershipYears = state?.selectedOwnershipYears || storedOwnershipYears || yearOfCarImplicit;

  console.log('Received data from the location state:', selectedBrand, selectedModel, selectedYearOfCar, selectedFuelType, yearOfCarImplicit, fuelTypeImplicit);
  console.log('Received state in ResultsPage:', state);
  console.log('ResultsPage is mounted.');
  console.log('fuelTypeImplicit:', fuelTypeImplicit)

  // console.log('higher price', higherPrice)
  
  // Add a callback function that handles monthlyDepreciation being updated so that it can be passed as a prop 
  // from one child (DepreciationTable) to another (LoanCost) via the parent (ResultsPage)
  const [monthlyDepreciation, setMonthlyDepreciation] = useState(0);

  const handleMonthlyDepreciationChange = useCallback((newMonthlyDepreciation) => {
    console.log('New Monthly Depreciation:', newMonthlyDepreciation);
    setMonthlyDepreciation(newMonthlyDepreciation);
  }, []);

  const [userWantsInsurance, setUserWantsInsurance] = useState(null);
  const insuranceTypes = ["Hel: 500SEK", "Halv: 750SEK"]; 
  const [insuranceType, setInsuranceType] = useState('');
  const [selectedInsuranceType, setSelectedInsuranceType] = useState(null);
  const [userWantsLoan, setUserWantsLoan] = useState(null);

  const handleInsuranceSelection = (type) => {
    setSelectedInsuranceType(type);
    setUserWantsInsurance(true);
    scrollToSection(loanSectionRef);
  };

 
  // State to manage the comparison data
  // const [comparisonData, setComparisonData] = useState([]);

  // const [isFirstVisitToResults, setIsFirstVisitToResults] = useState(true); // Track the first visit
  const { isFirstVisitToResults, setIsFirstVisitToResults, isComparisonMode, setIsComparisonMode, higherPrice, lowerPrice } = useComparisonMode();
  let newData; 
  // const [carData, setCarData] = useState([]); // Store car data for comparison
  const [newCarData, setNewCarData] = useState(null);

  // Function to add a car's data to the comparison array
  const [selectedCars, setSelectedCars] = useState([]); 

  const [selectedYearPrice, setSelectedYearPrice] = useState(null);

  const handleSelectedYearPriceChange = (price) => {
    setSelectedYearPrice(price);
  }

  // // Function to add a car's data to the comparisonData array
  // const addToComparison = (carData) => {
  //   setComparisonData([...comparisonData, carData]);
  // };

  // // Function to remove a car's data from the comparisonData array
  // const removeFromComparison = (carData) => {
  //   const updatedData = comparisonData.filter((data) => data !== carData);
  //   setComparisonData(updatedData);
  // };
  
    // Deserialize the comparisonData
// const deserializedData = JSON.parse(comparisonData);

  const getFuelType = () => {
    // Determine the fuel type to use (either selected or implicit)
    return selectedFuelType || fuelTypeImplicit || '';
  };

  const getYearOfCar = () => {
    // Determine the year of the car to use (either selected or implicit)
    return selectedYearOfCar || yearOfCarImplicit || '';
  };
  


useEffect(() => {
  console.log('Selected Cars updated:', selectedCars);
}, [selectedCars]);

// TAKE USER TO SUMMARY 
  const handleSummaryClick = () => {
    const newData = {
      selectedBrand,
      selectedModel,
      fuelType: getFuelType(),
      yearOfCar: getYearOfCar(),
      monthlyDepreciation,
      higherPrice,
      lowerPrice,
      userWantsLoan,
      userWantsInsurance,
      selectedOwnershipYears
    };
  

  handleSummaryClickLogic(navigate, setSelectedCars, selectedCars, newData);
  scrollToSection(summarySectionRef);
};
  //   // Assume the last car in the array is the one to be updated
  //   const lastCarIndex = selectedCars.length - 1;
  
  //   // Update the last selected car in the array
  //   setSelectedCars((prevSelectedCars) => {
  //     const updatedCars = [...prevSelectedCars];
  //     updatedCars[lastCarIndex] = newData;
  //     // setIsFirstVisitToResults(false);
  //     // setIsComparisonMode(true);
  //     navigate("/summary", { 
  //       state: {
  //         selectedCars: updatedCars 
  //       }
  //     });
  //      // Log monthlyDepreciation for all cars in selectedCars
  //     console.log("Monthly Depreciation for each car:", updatedCars.map(car => car.monthlyDepreciation));
  //     return updatedCars;
      
  //   });
  // }
  const insuranceSectionRef = useRef(null);
  const loanSectionRef = useRef(null);
  const summarySectionRef = useRef(null);

  const scrollToSection = (sectionRef) => {
    if (sectionRef.current) {
    console.log(`Scrolling to section: `, sectionRef);
    sectionRef.current.scrollIntoView({ behavior: 'smooth'});
  } else {
    console.log('Failed to scroll. Invalid sectionRef:', sectionRef);
  };
}

setTimeout(() => {
  window.scrollBy(0, 110); 
}, 300); 

useEffect(() => {
  if (userWantsInsurance && selectedFuelType && loanSectionRef.current) {
    scrollToSection(loanSectionRef);
  }
}, [userWantsInsurance, selectedFuelType]);

useEffect(() => {
  if (loanSectionRef.current) {
    console.log('Loan Section DOM node:', loanSectionRef.current);
  } else {
    console.log('Loan Section Ref is not yet assigned.');
  }
}, []);

useEffect(() => {
  console.log('Loan Section Ref:', loanSectionRef.current);
}, [userWantsInsurance, selectedFuelType]);


 

  useEffect(() => {
    console.log('Received state in ResultsPage:', location.state);
  
  // Check if location state is present
  if (location.state && location.state.selectedCars) {
    // Set selectedCars to the cars in location.state
    setSelectedCars(location.state.selectedCars);
  }
  }, [location.state]);

  return ( 
    <>
  <Wrapper>
    
{/*       
      <p>THIS IS TO CHECK IT IS CORRECT</p>
      <h1>You have expressed interest in:</h1>
      {selectedCars.map((car, index) => (
        car && (
          <div key={car._id}>
            <h1>Car {index + 1}:</h1>
            {console.log(`Car ${index+ 1} Details:`, car)}
            <ul>
              <li>{`${getFuelType()}`} {car.selectedBrand} {car.selectedModel} from {`${getYearOfCar()}`}</li>
            </ul>
          </div>
        )
      ))} */}

    
      <ResultsTitle>Dina resultat</ResultsTitle>
      <SelectedCarContainer>
        <SelectedCar>
          <div>{`${selectedBrand} ${selectedModel}`}</div><div>{`${getYearOfCar()}`} - {`${getFuelType()}`}</div>
        </SelectedCar>
        <SelectedCarYear>{`${selectedOwnershipYears}`} år</SelectedCarYear>
      </SelectedCarContainer>
  
      <br />
      <DepreciationTable 
          selectedBrand={selectedBrand} 
          selectedModel={selectedModel} 
          // getFuelType={getFuelType} 
          // getYearOfCar={getYearOfCar}
          getFuelType={() => selectedFuelType}
          getYearOfCar={() => selectedYearOfCar}
          onMonthlyDepreciationChange={handleMonthlyDepreciationChange}
          onSelectedYearPriceChange={handleSelectedYearPriceChange}
          selectedOwnershipYears={selectedOwnershipYears}
        />
      {/* Insurance Section */}  
      <Question ref={insuranceSectionRef}>Lägg till försäkring?</Question>
      <ButtonContainer>
        {/* <Button type="button" onClick={() => setUserWantsInsurance(true)}>Ja, tack!</Button> */}
        <ButtonContainerInsurance>
          <Button
              type="button"
              onClick={() => {
                handleInsuranceSelection("Hel");
                setUserWantsInsurance(true);
                scrollToSection(loanSectionRef);
              }}
              active={selectedInsuranceType === "Hel"}
              style={{padding: "1rem", minWidth: "4rem", alignSelf: "center" , flexGrow: "1", 
                width: "100%"}}

          >
              Hel
          </Button>
          <Button
            type="button"
            onClick={() => {
              handleInsuranceSelection("Halv");
              setUserWantsInsurance(true);
              scrollToSection(loanSectionRef);
            }}
            active={selectedInsuranceType === "Halv"}
            secondary={true}
            style={{padding: "1rem", minWidth: "4rem", alignSelf: "center" , flexGrow: "1", 
              width: "100%"}}
          >
            Halv
        </Button> 
       </ButtonContainerInsurance>
        <Button type="button" onClick={() => {
            setUserWantsInsurance(false)
            scrollToSection(loanSectionRef);
          }}
           tertiary={"true"}
           >
            Nej, tack!</Button>
      </ButtonContainer>

      {/* Loan Section */}
      {userWantsInsurance && selectedFuelType && (
        <div ref={loanSectionRef}>
          <InsuranceCost
            selectedBrand={selectedBrand} 
            selectedModel={selectedModel} 
            getFuelType={getFuelType}
            getYearOfCar={getYearOfCar}
            monthlyDepreciation={monthlyDepreciation}  
            higherPrice={higherPrice}
            lowerPrice={lowerPrice}
            userWantsInsurance={userWantsInsurance}
            isFirstVisitToResults={isFirstVisitToResults}
            setIsFirstVisitToResults={setIsFirstVisitToResults}
            selectedCars={selectedCars}
            setSelectedCars={setSelectedCars}
            selectedYearPrice={selectedYearPrice}
            selectedInsuranceType={selectedInsuranceType}
            selectedOwnershipYears={selectedOwnershipYears}
          />
        </div>
        // This should give insurance costs, rendered from an insurance component
        // Then it should direct to button "would you like to add loan costs"
        // Yes or no
        // Giving a summary for 
        // Yes to insurance, yes to loan => then, would you like to compare with another car?
        // Yes to insurance, no to loan => then, would you like to compare with another car? 
      )}
      {userWantsInsurance === false && (
        <div>
          <Question>Lägg till billån?</Question>
          <LoanCostInfo>Vi kan hjälpa dig att uppskatta de månatliga betalningarna för ett <b>{selectedOwnershipYears}-årigt</b> lån med en fast ränta på 7,95%.</LoanCostInfo>
          <ButtonContainer>
            <Button type="button" onClick={() => setUserWantsLoan(true)}>Ja, tack!</Button>
            {/* <button type="button" onClick={(navigateToComparison) => setUserWantsLoan(false)}>No, help me compare another car!</button>     */}
            <Button type="button" onClick={() => setUserWantsLoan(false)} secondary={true} style={{marginBottom: "2.5rem"}}>Nej, tack!</Button>    
          </ButtonContainer>
          {userWantsLoan === true && (
            // NO TO INSURANCE, YES TO LOAN
              <LoanCost 
                selectedBrand={selectedBrand} 
                selectedModel={selectedModel} 
                getFuelType={getFuelType}
                getYearOfCar={getYearOfCar}
                monthlyDepreciation={monthlyDepreciation} 
                higherPrice={higherPrice}
                lowerPrice={lowerPrice}
                userWantsLoan={userWantsLoan}
                userWantsInsurance={userWantsInsurance}
                isFirstVisitToResults={isFirstVisitToResults}
                setIsFirstVisitToResults={setIsFirstVisitToResults}
                selectedCars={selectedCars}
                setSelectedCars={setSelectedCars}
                selectedYearPrice={selectedYearPrice}
                selectedOwnershipYears={selectedOwnershipYears}
                />
          )}    

          {/* Summary Section */}
          {userWantsLoan === false && (
            // NO TO INSURANCE, NO TO LOAN 
            <>
              <div ref={summarySectionRef}>
                {/* <CompareQuestion>Jämföra med en annan bil?</CompareQuestion> */}
                <CompareQuestion>Visa resultat?</CompareQuestion>
                {/* <button type="button" onClick={() => addToComparison(carData)}>
                  Compare with another car!
                </button> */}
                <ButtonContainer>
                  {/* <CompareButton
                    //  onClick={handleSummaryClick}
                    selectedBrand={selectedBrand}
                    selectedModel={selectedModel}
                    getFuelType={getFuelType}
                    getYearOfCar={getYearOfCar}
                    monthlyDepreciation={monthlyDepreciation}
                    userWantsLoan={userWantsLoan}
                    userWantsInsurance={userWantsInsurance}
                    setIsFirstVisitToResults={setIsFirstVisitToResults}
                    setIsComparisonMode={setIsComparisonMode}
                    setSelectedCars={setSelectedCars}
                    selectedCars={selectedCars}
                    selectedOwnershipYears={selectedOwnershipYears}
                    navigate={navigate}
                    location={location}
                  /> */}
                  <SummaryButton
                    type="button"
                    onClick={handleSummaryClick}
                    selectedBrand={selectedBrand}
                    selectedModel={selectedModel}
                    getFuelType={getFuelType}
                    getYearOfCar={getYearOfCar}
                    monthlyDepreciation={monthlyDepreciation}
                    higherPrice={higherPrice}
                    lowerPrice={lowerPrice}
                    userWantsLoan={userWantsLoan}
                    userWantsInsurance={userWantsInsurance}
                    setIsFirstVisitToResults={setIsFirstVisitToResults}
                    setIsComparisonMode={setIsComparisonMode}
                    setSelectedCars={setSelectedCars}
                    selectedCars={selectedCars}
                    selectedOwnershipYears={selectedOwnershipYears}
                    >
                      {/* Nej, visa sammanfattning! */}
                      Visa mig!!
                    </SummaryButton> 
                  </ButtonContainer>
                {/* <button type="button" onClick={handleSummaryClick}>No thank you, please show me my results!</button> */}
              </div>
            </>
          )}
          {/* {console.log('selectedCars when isFirstVisitIsFalse (subsequent visit):', selectedCars)}
          {console.log('selectedCars length (dependent on first visit or not):', selectedCars.length)} */}
        </div>
      )}
      </Wrapper>
    </>
  )
}
      
        
        