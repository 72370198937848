import styled from 'styled-components';
import { typography } from '../../styles/mixins';
import { ReactComponent as BurgerIcon } from '../../assets/Burger.svg';


export const StyledBurger = styled(BurgerIcon)`

    position: absolute; 
    top: 0.8rem; 
    right: 0.8rem; 
    height: 2.5rem;
    width: 2.5rem;
`

export const Nav = styled.nav`
    display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
`;

export const NavList = styled.ul`
    list-style: none;
    margin: 0;
    padding: 3.5rem 1rem 1rem 1rem;
    color: var(--color-neutral-4-white);
    font-weight: bold;
    /* background-color: var(--color-neutral-1-black); */
    background-color: rgba(0, 0, 0, 0.85);
    ${typography('p3', 'header')};
    border-radius: 0.75rem;

    @media (min-width: 744px) {
        ${typography('p3', 'header')};

    }

`
export const NavItem = styled.li`
    ${typography('p3', 'header')};
    margin: 1.2rem;

    &:hover {
    font-size: 1.8rem; /* Adjust the size as needed */
  }

    @media (min-width: 744px) {
        ${typography('p2')};
        margin: 2rem 1rem;
    }
`

export const NavLink = styled.a`
  text-decoration: none;
  ${typography('p3', 'header')};
  transition: font-size 0.3s ease;

  &:hover {
    font-size: 1.8rem;
  }
  `