import styled from 'styled-components';

export const WrapperContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 1rem;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    padding-bottom: 2rem;

    @media (min-width: 744px) {
      max-width: 850px;
      align-items: center;
      margin-left: auto;
      margin-right: auto;
  }

  @media (min-width: 1440px) {
      /* max-width: none; */
      max-width: 1000px;
      align-items: center;

      margin-left: auto;
      margin-right: auto;
  }
`

export const WrapperWelcome = styled(WrapperContainer)`
    display: flex;
    align-items: center;
`

