import styled from 'styled-components';
import { typography } from '../../styles/mixins';
import { ReactComponent as CarLogo } from '../../assets/CarLogo.svg';

import { Link } from 'react-router-dom';


export const Menu = styled.div`
    background-color: var(--color-neutral-1-black);
    background-color: rgba(0, 0, 0, 0.85);
    /* background-color: var(--color-neutral-3-light-grey); */
    height: 4rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
  `

export const Brand = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    /* gap: 2rem; */
    ${typography('p5', 'header')}

    @media (min-width: 744px) {
        ${typography('h5')};
    }

    @media (min-width: 1440px) {
        ${typography('h4')};
    }
`

export const CompanyName = styled.h1`
    color: var(--color-primary);
`
export const StyledLogoLink = styled(Link)`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
    `;

export const StyledLogo = styled.img`
  height: 4.3rem;
  width: 4.3rem;
`;

export const NavListMobile = styled.div`
    display: block;

    @media (min-width: 1440px) {
          display: none;
      }

`
export const NavListNonMobile = styled.div`
    display: none;

    @media (min-width: 1440px) {
          display: flex;
      }
`
export const NavList = styled.ul`
    list-style: none;
    color: var(--color-neutral-4-white);
    background-color: var(--color-neutral-1-black);
    ${typography('p2', 'header')};
    
    @media (min-width: 1440px) {
          display: flex;
          flex-direction: row;
          align-self: center;
          padding-right: 3rem;
          gap: 1.5rem;          
      }

`
export const NavItem = styled.li`
    ${typography('p2', 'header')};

    /* @media (min-width: 744px) {
        ${typography('p2')};
    } */

`

export const NavLink = styled.a`
  text-decoration: none;
  color: #fff;
  /* font-size: 1rem; */
  transition: font-size 0.3s ease;

  &:hover {
    font-size: 1.8rem;
  }
  `