import styled from 'styled-components';
import { typography } from '../../styles/mixins';

export const DropdownMenuWrapper = styled.div`
    display: flex;
    flex-direction: row;
    /* align-self: flex-start; */
    align-items: center;
    justify-content: space-between;
    padding-top: 0.725rem;

    @media (min-width: 440px) and (max-width: 497px) {
    padding-left: 10%; 
    padding-right: 10%;
    }

    @media (min-width: 498px) and (max-width: 630px) {
      padding-left: 16%; 
      padding-right: 16%;
    }

    @media (min-width: 631px) and (max-width: 651px) {
      padding-left: 19%; 
      padding-right: 19%;
    }



  @media (min-width: 652px) and (max-width: 743px) {
    padding-left: 23%; 
    padding-right: 23%;

    }

    @media (min-width: 744px) {
    gap: 1rem;
    }

    @media (min-width: 1440px) {
    gap: 1.5rem;
    }
    
`

export const StyledLabel = styled.label`
    color: var(--color-text-on-background-light);
    /* color: var(--color-neutral-2-grey); */
    border-radius: 0.45rem;
    /* padding: 0.175rem; */
    padding: 0.3rem;
    max-width: 7rem;
    ${typography('p3', 'paragraph')};


    @media (min-width: 744px) {
    align-self: center;
    ${typography('p2')};
    min-width: 8rem;
    font-weight: normal;

  }

  @media (min-width: 1440px) {
    ${typography('p1')};
    font-weight: normal;

  }
`

export const SelectValue = styled.select`
    ${typography('p3', 'paragraph')};
    /* padding: 0.3rem; */
    padding: 0.5rem;
    border-radius: 0.45rem;
    appearance: none; /* Remove default arrow */
    /* Custom dropdown arrow using a pseudo-element */
    background: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><path fill="none" stroke="%23333" stroke-width="1.5" d="M5 8l5 5 5-5"/></svg>') no-repeat right 0.5rem center;
    background-size: 1rem; /* Adjust size of custom arrow */
    background-color: #fff; /* Adjust background color of select box */
    min-width: 200px; /* Set minimum width for the dropdown box */


  &:focus {
    border-color: var(--color-primary); /* Adjust border color on focus */
    outline: none; /* Remove default outline */
  }


    @media (min-width: 744px) {
      ${typography('p2')};
      align-self: center;
      min-width: 250px;
    }

    @media (min-width: 1440px) {
      ${typography('p1')};
    }
`

export const Option = styled.option`
 

`