import styled from 'styled-components';
import { typography } from '../../styles/mixins';


export const WrapperLoading = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 2.5rem;
    padding-right: 1rem;
    padding-left: 1rem;
`
export const LoadingSign = styled.h1`
    color: var(--color-neutral-2-grey);
    ${typography('h3', 'header')};
    display: flex;
    text-align: center;


    @media (min-width: 744px) {
        ${typography('h2')};
        padding-top: 1rem;
        padding-bottom: 1rem;
    }

    @media (min-width: 1440px) {
        ${typography('h1')};
        padding-top: 1.375rem;
        padding-bottom: 1.375rem;
    }

`
export const StyledCarLottie = styled.div`

    --height: 200px;
    --width: 300px;
    height: var(--height);
    width: var(--width);
  
  
  @media (min-width: 744px) {
  
      --height: 300px;
      --width: 400px;
      height: var(--height);
      width: var(--width);
    
  }
  
  @media (min-width: 1440px) {
      --height: 350px;
      --width: 450px;
      height: var(--height);
      width: var(--width);
  }

  `