import React from 'react';
import { StyledButton } from './Button.styles';

export const Button = ({ children, onClick, secondary, tertiary, disabled, ...rest }) => {
    return (
        <StyledButton onClick={onClick} secondary={secondary} tertiary={tertiary} disabled={disabled} {...rest}>
            {children}
        </StyledButton>
    );
};
