import React from 'react';
// import { Link } from 'react-router-dom';

export const AboutUs = () => {

    return (

        <h1>This is the About Us page</h1>
    )
}

