import styled from 'styled-components';
import { typography } from '../../styles/mixins';

export const CurrentValuation = styled.h3`
    color: var(--color-neutral-1-black);
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    max-width: 100%;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    ${typography('h6', 'paragraph')};
    font-weight: normal;

    span {
        font-weight: bold;
    }

    @media (min-width: 744px) {
        ${typography('h5')};
        font-weight: normal;
        align-items: center;
        justify-content: center;
        /* padding-top: 1rem;
        padding-bottom: 1rem; */
    }

    @media (min-width: 1440px) {
        ${typography('h4')};
        font-weight: normal;
        /* padding-top: 1.375rem;
        padding-bottom: 1.375rem; */
        align-items: center;
 
    }
`

export const ExpectedValuation = styled.h3`
    color: var(--color-neutral-1-black);
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    max-width: 100%;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    ${typography('h6', 'paragraph')};
    font-weight: normal;

    span {
        font-weight: bold;
    }


    @media (min-width: 744px) {
        ${typography('h5')};
        font-weight: normal;
        align-items: center;
        justify-content: center;
        /* padding-top: 1rem;
        padding-bottom: 1rem; */
    }

    @media (min-width: 1440px) {
        ${typography('h4')};
        font-weight: normal;
        /* padding-top: 1.375rem;
        padding-bottom: 1.375rem; */
        align-items: center;
    }
`

export const DepreciationResult = styled.h3`
    color: var(--color-neutral-1-black);
    display: flex;
    align-self: flex-start;
    /* max-width: 100%; */
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    ${typography('h6', 'paragraph')};
    font-weight: normal;


    @media (min-width: 744px) {
        ${typography('h5')};
        font-weight: normal;
        align-self: center;
        justify-content: center;
        padding-top: 1rem;
        padding-bottom: 1rem;
    }

    @media (min-width: 1440px) {
        ${typography('h4')};
        font-weight: normal;
        padding-top: 1.375rem;
        padding-bottom: 1.375rem;
        align-items: center;
     
    }

`

export const DepreciationValue = styled.h4`

    color: var(--color-neutral-1-black);
    display: flex;
    align-self: flex-start;
    width: 100%;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    ${typography('h6', 'paragraph')};
    font-weight: bold;
    background-color: var(--color-neutral-3-light-grey);
    /* text-decoration: underline;
    text-decoration-color: var(--color-neutral-2-grey);
    text-decoration-thickness: 0.2em; */

    @media (min-width: 744px) {
        ${typography('h5')};
        font-weight: bold;
        align-self: center;
        justify-content: center;
        padding-top: 1rem;
        padding-bottom: 1rem;  
        min-width: 800px;
    }

    @media (min-width: 1440px) {
        ${typography('h4')};
        font-weight: bold;
        padding-top: 1.375rem;
        padding-bottom: 1.375rem;
        min-width: 950px;
        align-items: center;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        
    }
`