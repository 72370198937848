import React, { useLayoutEffect } from 'react';
import { useComparisonMode } from '../../context/ComparisonModeContext';
import { isFromResultsPage } from '../../utils/utils.js';
import { Button } from '../../ui/Button/Button';

export const CompareButton = ({
  selectedBrand,
  selectedModel,
  getFuelType,
  getYearOfCar,
  monthlyDepreciation,
  userWantsLoan,
  userWantsInsurance,
  // isFirstVisitToResults,
  // setIsFirstVisitToResults,
  // setIsComparisonMode,
  setSelectedCars,
  selectedOwnershipYears,
  navigate,
  location,
  selectedCars, // Include the selectedCars prop
  checkFromResultsPage, // Add a new prop to indicate if it's from ResultsPage
}) => {
  console.log("CompareButton is rendering");
  // console.log("isFirstVisitToResults present in CompareButton", isFirstVisitToResults);
  // console.log("setIsFirstVisitToResults present in CompareButton", setIsFirstVisitToResults);

  // const [isFirstVisitToResults, setIsFirstVisitToResults] = useState(true);
  const { isFirstVisitToResults, setIsFirstVisitToResults, setIsComparisonMode } = useComparisonMode();


  useLayoutEffect(() => {
    setIsFirstVisitToResults(true); // Set isFirstVisitToResults to true when the component mounts
  }, [setIsFirstVisitToResults]);

  const handleCompareClick = () => {
    console.log("Location object:", location);

    const fromResultsPage = isFromResultsPage(location);


    if (fromResultsPage) {
      console.log("fromResultsPage", fromResultsPage)

      const newData = {
        selectedBrand,
        selectedModel,
        fuelType: getFuelType(),
        yearOfCar: getYearOfCar(),
        monthlyDepreciation,
        userWantsLoan,
        userWantsInsurance,
        selectedOwnershipYears
      };

      // If it's from ResultsPage, add the new data to the existing selectedCars
      setSelectedCars((prevSelectedCars) => {
        const updatedCars = [...prevSelectedCars, newData];
        setIsFirstVisitToResults(false);
        setIsComparisonMode(true);
        navigate("/comparison", { state: { selectedCars: updatedCars } });
        return updatedCars;
      });
    } else {
      console.log("fromResultsPage", fromResultsPage)

      // If it's not from ResultsPage, just pass the selectedCars array to ComparisonPage
      // and set isFirstVisitToResults to false via a new function

      
      setIsFirstVisitToResults(false);
      setIsComparisonMode(true);
      navigate("/comparison", { state: { selectedCars } });
    }
  };

  return (
    <Button onClick={handleCompareClick}>Jämför</Button>
  );
};



// import React, { useState } from 'react';

// export const CompareButton = ({
//   selectedBrand,
//   selectedModel,
//   getFuelType, //C ?
//   getYearOfCar, //C
//   monthlyDepreciation,
//   userWantsLoan,
//   userWantsInsurance,
//   setIsFirstVisitToResults,
//   setIsComparisonMode,
//   setSelectedCars,
//   navigate,
//   selectedCars, // Include the selectedCars prop
// }) => {
//   console.log("CompareButton is rendering");
//   // useEffect(() => {
//   //   console.log("getFuelType in CompareButton:", getFuelType());
//   //   // You can also log other props like getYearOfCar, selectedCars, etc.
//   // }, [getFuelType, getYearOfCar, selectedCars]);


//   const [errorMessage, setErrorMessage] = useState("");

//   const handleCompareClick = () => {
//     // console.log("getFuelType:", fuelType);
//     // console.log("getYearOfCar:", yearOfCar);
//     console.log("getFuelType in CompareButton:", getFuelType);

//     const newData = {
//       selectedBrand,
//       selectedModel,
//       // getFuelType: getFuelType(),
//       // getYearOfCar: getYearOfCar(),
//       fuelType: getFuelType(),
//       yearOfCar: getYearOfCar(),
//       monthlyDepreciation,
//       userWantsLoan,
//       userWantsInsurance,
//       selectedCars
//     };
  
//     if (!selectedCars || selectedCars.length === 0) {
//       setErrorMessage("No cars to update. Add a car first.");
//       return;
//     }
  
//     // Assume the last car in the array is the one to be updated
//     const lastCarIndex = selectedCars.length - 1;
  
//     // Update the last selected car in the array
//     setSelectedCars((prevSelectedCars) => {
//       const updatedCars = [...prevSelectedCars];
//       updatedCars[lastCarIndex] = newData;
//       setIsFirstVisitToResults(false);
//       setIsComparisonMode(true);
//       navigate("/comparison", { state: { selectedCars: updatedCars } });
//       return updatedCars;
//     });
//   };
  

//   return (
//     <>
//       {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
//       <button onClick={handleCompareClick}>Compare with another car!</button>
//     </>
//   );
// };


// // // THIS ONE WORKS BUT IT ADDS THE LAST CAR IN THE ARRAY AGAIN 
// // import React, { useState } from 'react';

// // export const CompareButton = ({
// //   selectedBrand,
// //   selectedModel,
// //   getFuelType,
// //   getYearOfCar,
// //   monthlyDepreciation,
// //   userWantsLoan,
// //   userWantsInsurance,
// //   setIsFirstVisitToResults,
// //   setIsComparisonMode,
// //   setSelectedCars,
// //   navigate,
// //   selectedCars, // Include the selectedCars prop
// // }) => {
// //   console.log("CompareButton is rendering"); 

// //   const [errorMessage, setErrorMessage] = useState(""); 


// //   const handleCompareClick = () => {
// //     const newData = {
// //       selectedBrand,
// //       selectedModel,
// //       fuelType: getFuelType(),
// //       yearOfCar: getYearOfCar(),
// //       monthlyDepreciation,
// //       userWantsLoan,
// //       userWantsInsurance,
// //     };

// //     // Check if the selected car is already in the list
// //     const isCarAlreadySelected = (selectedCars ?? []).some(
// //       (car) => car.selectedBrand === selectedBrand && car.selectedModel === selectedModel 
// //       // && car.selectedFuelType === getFuelType() 
// //       // && car.selectedYearOfCar === getYearOfCar()
// //     );

// //     if (isCarAlreadySelected) {
// //       setErrorMessage("You have already selected this car previously.");
// //       return;
// //     }

// //     // If not, proceed to add the car to the list
// //     setErrorMessage(""); // Clear any previous error messages

  
    
// //     setSelectedCars((prevSelectedCars) => {
// //       const updatedCars = [...prevSelectedCars, newData];
// //       setIsFirstVisitToResults(false);
// //       setIsComparisonMode(true);
// //       navigate("/comparison", { state: { selectedCars: updatedCars } });
// //       return updatedCars;
// //     });
// //   };

// //   return (
// //     <>
// //       {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
// //       <button onClick={handleCompareClick}>Compare with another car!</button>
// //     </>
// //   );
// // };




// // import React from 'react';

// // export const CompareButton = ({
// //   selectedBrand,
// //   selectedModel,
// //   getFuelType,
// //   getYearOfCar,
// //   monthlyDepreciation,
// //   userWantsLoan,
// //   userWantsInsurance,
// //   setIsFirstVisitToResults,
// //   setIsComparisonMode,
// //   setSelectedCars,
// //   navigate,
// //   selectedCars,
// // }) => {
// //   console.log("CompareButton is rendering");

// //   const handleCompareClick = () => {
// //     // Create a new car object with the additional properties
// //     const newData = {
// //       selectedBrand,
// //       selectedModel,
// //       fuelType: getFuelType(),
// //       yearOfCar: getYearOfCar(),
// //       monthlyDepreciation,
// //       userWantsLoan,
// //       userWantsInsurance,
// //     };

// //     // Log newData to satisfy TypeScript
// //     console.log(newData);

// //     // Find the existing car data in selectedCars array
// //     const updatedCars = selectedCars.map((car) =>
// //       car.selectedBrand === selectedBrand && car.selectedModel === selectedModel
// //         ? { ...car, monthlyDepreciation, userWantsLoan, userWantsInsurance }
// //         : car
// //     );

// //     setIsFirstVisitToResults(false);
// //     setIsComparisonMode(true);
// //     navigate("/comparison", { state: { selectedCars: updatedCars } });
// //     setSelectedCars(updatedCars);
// //   };

// //   return (
// //     <button onClick={handleCompareClick}>Compare with another car!</button>
// //   );
// // };
