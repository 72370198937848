
import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useComparisonMode } from '../../context/ComparisonModeContext';
import { useNavigate, useLocation } from 'react-router-dom';
import { DropdownMenu } from '../../ui/DropdownMenu/DropdownMenu';
// import { ComparisonTable } from '../../ui/ComparisonTable/ComparisonTable';
import { ConfirmationButton } from '../ConfirmationButton/ConfirmationButton';
import { Wrapper } from '../Wrapper/Wrapper';
import { MainQuestion, SelectedCar, Question, ButtonContainer, WarningMessage } from './ComparisonPage.styles'
import { Loading } from '../../ui/Loading/Loading';
// import { ReactComponent as Graph } from '../../assets/Graph.svg';
// import { Icon } from '../../ui/Icon/Icon';
// import { Button } from '../../ui/Button/Button';

// import { useLocation } from 'react-router-dom';


export const ComparisonPage = () => {

//   const location = useLocation();
// const state = location.state;
// const {
//   selectedBrand,
//   selectedModel,
//   selectedYearOfCar,
//   selectedFuelType,
// } = state || {};
  const {isComparisonMode, setIsComparisonMode, ownershipYears, setOwnershipYears, selectedOwnershipYears, setSelectedOwnershipYears } = useComparisonMode();
  const [carBrand, setCarBrand] = useState([]);
  const [carModel, setCarModel] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState('');
  const [selectedModel, setSelectedModel] = useState('');
  const selectedModelString = String(selectedModel);
  const [tableData, setTableData] = useState([]);
  const [data, setData] = useState([]); 
  const [aggregationResults, setAggregationResults] = useState([]); 
  const [organizedData, setOrganizedData] = useState({});
  const [fuelType, setFuelType] = useState([]);
  const [yearOfCar, setYearOfCar] = useState([]);
  const [selectedFuelType, setSelectedFuelType] = useState(''); // {} THROWS NO ERRORS , as well as ''
  const [selectedYearOfCar, setSelectedYearOfCar] = useState(''); // THIS THROWS NO ERRORS
  const [isLoading, setIsLoading] = useState(false);
  // const [bothDropdownsSelected, setBothDropdownsSelected] = useState(false);
  // const [depreciationData, setDepreciationData] = useState([]); // Data for the depreciation table
  // const { selectedBrand, selectedModel, selectedYearOfCar, selectedFuelType } = props;
  const navigate = useNavigate();
  const location = useLocation();
  // const { isComparisonMode } = location.state || {};
  

  // Define the onChange handler for the dropdown
  const handleOwnershipYearsChange = (selectedYear) => {
    setSelectedOwnershipYears(selectedYear); // Update the selected borrow year
    console.log("Selected Number of Ownership Years:", selectedYear);
  };

  useEffect(()=> {
    if (!selectedBrand || !selectedModel || yearOfCar.length === 0 || !selectedFuelType) {
      setSelectedOwnershipYears('');
    }
  }, [selectedBrand, selectedModel, yearOfCar, selectedFuelType, setSelectedOwnershipYears]);


  // Use the isComparisonMode value to set the initial state
  // const [isComparisonMode, setIsComparisonMode] = useState(isComparisonMode || false);
// Use this state variable to track selected cars
const [selectedCars, setSelectedCars] = useState(location.state?.selectedCars || []);

useEffect(() => {
  // This effect will run when the component mounts and when selectedCars changes
  console.log("Selected Cars in ComparisonPage:", selectedCars);
}, [selectedCars]);



  
  // Fetch car brands and car models
  const fetchCarBrandsAndModels = useCallback(async () => {
    setIsLoading(true);
    try {
      // const response = await fetch('https://localhost:10000/cars');

      const response = await fetch('https://minbilkostnad-backend.onrender.com/cars');

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const responseData = await response.json(); //RESPONSE DATA RENAMED FROM DATA 
      console.log('API Response:', responseData);
      if (responseData.success) { 
        setData(responseData.response);
        const brands = Array.from(new Set(responseData.response.map((car) => car.carBrand)));
        setCarBrand(brands);

        const models = responseData.response
        .filter((car) => car.carBrand === selectedBrand)
        .map((car) => car.carModel);
      const allowedModels = Array.from(new Set(models));
      setCarModel(allowedModels);

      console.log('Car Brands:', brands);
      console.log('Car Models:', allowedModels);

      } else {
        console.error('Request was successful, but data.success is not true', data);
      }
    } catch (error) {
      console.error('Error fetching car data', error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  // old code was here...
  useEffect(() => {
    fetchCarBrandsAndModels();
  }, [fetchCarBrandsAndModels]);

    // Filter car models based on the selected car brand

  useEffect(() => {
    if (selectedBrand) { // Only filter when a brand is selected
      console.log("selected brand", selectedBrand ) 
      const filteredModels = data
      .filter((car) => car.carBrand === selectedBrand && car.carModel) // Filter out undefined and empty carModel values
      .map((car) => car.carModel);

      // Log allowed models before setting state
    const allowedModels = Array.from(new Set(filteredModels));
    
    console.log('Filtered Models:', filteredModels);
    console.log('Allowed Models:', allowedModels);
    
    setCarModel(allowedModels);
    
  } else { 
      setCarModel([]); // Reset car models if no brand is selected
    } 
  }, [data, selectedBrand]);


  useEffect(() => {
    if (selectedBrand && selectedModel) {
      // console.log('Before fetch');
      // fetch(`http://localhost:7001/cars-aggregation?selectedModel=${selectedModel}`)

      fetch(`https://minbilkostnad-backend.onrender.com/cars-aggregation?selectedModel=${encodeURIComponent(selectedModelString)}`)
        .then(async (response) => {
          console.log('Response:', response);
  
          if (!response) {
            console.error('No response received');
            throw new Error('No response received');
          }
  
          if (!response.ok) {
            const errorMessage = `Network response was not ok (Status: ${response.status})`;
            console.error(errorMessage);
            throw new Error(errorMessage);
          }
  
          const data = await response.json();
  
          if (data.success) {
            setAggregationResults(data.response);
          } else {
            console.error('Aggregation request was not successful', data);
          }
        })
        .catch((error) => {
          console.error('Error fetching aggregation data:', error);
        })
        .finally(() => {
          // console.log('After fetch');
        });
   console.log("Selected Model:", selectedModel); 

    }
  }, [selectedBrand, selectedModel, selectedModelString]); //fetchCarData

const messageRef = useRef(null);

// Fetch available years for the selected brand and selected model 

useEffect(() => {
  if (selectedBrand && selectedModel) {
      // fetch(`http://localhost:7001/year?selectedBrand=${encodeURIComponent(selectedBrand)}&selectedModel=${encodeURIComponent(String(selectedModel))}`)
      fetch(`https://minbilkostnad-backend.onrender.com/year?selectedBrand=${encodeURIComponent(selectedBrand)}&selectedModel=${encodeURIComponent(selectedModelString)}`)

      .then(async (response) => {
          if (!response.ok) {
              console.error('Network response was not ok');
              return [];
          }
  const data = await response.json();
          console.log('API response data:', data);
      if (data.success) {
          const filteredYears = data.response.filter(year => year >= 2016 && year <= 2024);
          
          if (filteredYears.length === 0) {
            if (messageRef.current) {
              messageRef.current.textContent = 'Tyvärr finns det inte tillräckligt med data för denna bil just nu. Vänligen välj en annan bil och checka in igen i framtiden.';
          }
        } else {
          if (messageRef.current) {
            messageRef.current.textConntent = '';
          }
        }
          setYearOfCar(filteredYears);
      } else {
          console.error('Years request was not successful', data);
  }
})
.catch((error) => {
  console.error('Error fetching years', error);
});
}
}, [selectedBrand, selectedModel, selectedModelString]);

// const defaultYearOfCarLabel = "Välj är: ";
// const defaultFuelTypeLabel = "Välj bränsletyp: ";

// useEffect(() => {
//   if (selectedBrand && selectedModel) {
//     fetchFuelTypes(selectedBrand, selectedModel);
//   }
// },  [selectedBrand, selectedModel]);

// Fetch fuel type for selected brand, model and year



  const fetchFuelTypes = useCallback((selectedBrand, selectedModel, selectedYearOfCar) => {
    
  
    // const baseUrl = `http://localhost:7001/fuel?selectedBrand=${encodeURIComponent(selectedBrand)}&selectedModel=${encodeURIComponent(selectedModel)}`;
    // const baseUrl = `https://minbilkostnad-backend.onrender.com/fuel?selectedBrand=${encodeURIComponent(selectedBrand)}&selectedModel=${encodeURIComponent(selectedModelString)}`;

    // const apiUrl = selectedYearOfCar ? `${baseUrl}&selectedYearOfCar=${encodeURIComponent(selectedYearOfCar)}` : baseUrl;
      

    const apiUrl = `https://minbilkostnad-backend.onrender.com/fuel?selectedBrand=${encodeURIComponent(selectedBrand)}&selectedModel=${encodeURIComponent(selectedModelString)}&selectedYearOfCar=${encodeURIComponent(selectedYearOfCar)}`;
    console.log('Constructed API URL:', apiUrl);

    fetch(apiUrl)
      .then(async (response) => {
        console.log('API Response:', response);
          if (!response.ok) {
            console.error('Network response was not ok. Status:', response.status);
            throw new Error('Network response was not ok', response);
          }

  const data = await response.json();
      if (data.success) {
        const filteredFuelTypes = data.response.filter(fuelType => fuelType !== null && fuelType !== undefined);
        setFuelType(filteredFuelTypes);
        console.log('Filtered and fetched Fuel Types', filteredFuelTypes);
      } else {
        console.error('Fuel types request was not successful', data);
      }
          // setFuelType(data.response);
          // console.log('fuel type', data.response.length);
  })
.catch((error) => {
  console.error('Error fetching fuel types', error);
});
  // if (selectedYearOfCar !== defaultYearOfCarLabel && selectedFuelType !== defaultFuelTypeLabel) {
  //   setBothDropdownsSelected(true);
  // } else {
  //   setBothDropdownsSelected(false);
  // }    

//  }, [selectedBrand, selectedModel, selectedYearOfCar]);
}, [setFuelType, selectedModelString]);


// useEffect(() => {
//   if (selectedBrand && selectedModel) {
//     if (selectedYearOfCar) {
//       fetchFuelTypes(selectedBrand, selectedModel, selectedYearOfCar);
//     } else {
//       setFuelType([]); // Keep fuelType empty until a year is selected
//     }
//   } else {
//     setFuelType([]); // Clear fuel types if no brand or model is selected
//   }
// }, [selectedBrand, selectedModel, selectedModelString, selectedYearOfCar, fetchFuelTypes]);

useEffect(() => {
 
  if (selectedBrand && selectedModel && selectedYearOfCar) {
    
      fetchFuelTypes(selectedBrand, selectedModel, selectedYearOfCar);
    } else {
      setFuelType([]);
    }
   
  }, [selectedBrand, selectedModel, selectedModelString, selectedYearOfCar, fetchFuelTypes]);

  console.log('yearOfCar', yearOfCar.length)
  console.log('fuelType', fuelType.length)

const [shouldRenderContent, setShouldRenderContent] = useState(false);

// useEffect(() => {
  // Check if both `yearOfCar` and `fuelType` have values.
//   if (yearOfCar.length >= 1 && fuelType.length >= 1) {
//     setShouldRenderContent(true);
//   } else {
//     setShouldRenderContent(false);
//   }
// }, [yearOfCar, fuelType]);

// Add a clause for when renderContent happens 


useEffect(() => {
  if (selectedOwnershipYears !== '') {
    setShouldRenderContent(true);
  } else {
    setShouldRenderContent(false);
  }
}, [selectedOwnershipYears]);

console.log('OwnershipYearsCheck', selectedOwnershipYears);


// useEffect(() => {
//   if (yearOfCar.length > 0) {
//     setShouldRenderContent(true);
//   } else {
//     setShouldRenderContent(false);
//   }
// }, [yearOfCar]);

// console.log('yearOfCarCheck', yearOfCar.length)


// console.log('selectedBrandResult:', selectedBrand)
// console.log('selectedModelResult:', selectedModel)
// console.log('selectedYearOfCarResult:', selectedYearOfCar)
// console.log('selectedFuelTypeResult:', selectedFuelType)

const navigateToResults = (comparisonData) => {
  console.log('NtR Selected Brand:', selectedBrand);
  console.log('NtR Selected Model:', selectedModel);
  console.log('NtR Selected Year Of Car:', selectedYearOfCar);
  console.log('NtR Selected Fuel Type:', selectedFuelType);

  let yearOfCarToUse = selectedYearOfCar; // Default to the selectedYearOfCar

  // Check if selectedYearOfCar is not set (null, undefined, or empty string)
  // and if yearOfCar[0] is available, use yearOfCar[0]
  if (!yearOfCarToUse && yearOfCar.length > 0) {
    yearOfCarToUse = yearOfCar[0];
  }

  let fuelTypeToUse = selectedFuelType; // Default to the selectedFuelType

  // Check if selectedFuelType is not set (null, undefined, or empty string)
  // and if fuelType[0] is available, use fuelType[0]
  if (!fuelTypeToUse && fuelType.length > 0) {
    fuelTypeToUse = fuelType[0];
  }

  // const serializedData = JSON.stringify(comparisonData); // Serialize the comparisonData

  console.log('Selected Cars before navigating to ResultsPage:', selectedCars);

  // const updatedSelectedCars = [...selectedCars, {
  //   selectedBrand: selectedBrand,
  //   selectedModel: selectedModel,
  //   selectedYearOfCar: yearOfCarToUse,
  //   selectedFuelType: fuelTypeToUse
  // }];

  const carDetails = {
    selectedBrand: selectedBrand,
    selectedModel: selectedModel,
    selectedYearOfCar: selectedYearOfCar,
    selectedFuelType: selectedFuelType,
  };

  // setSelectedCars(prevSelectedCars => {
  //   const updatedSelectedCars = [...prevSelectedCars, carDetails];
  //   console.log('Updated Selected Cars:', updatedSelectedCars);
  
  //   // Move navigate inside the callback
  //   navigate('/results', {
  //     state: {
  //       selectedBrand,
  //       selectedModel,
  //       selectedYearOfCar: yearOfCarToUse,
  //       selectedFuelType: fuelTypeToUse,
  //       selectedCars: updatedSelectedCars
  //     }
  //   });
  
  //   // Return the updatedSelectedCars so it's reflected in the state
  //   return updatedSelectedCars;
  // });
  




  // THIS MUTATES THE STATE DIRECTLY AND DOES NOT ENSURE PROPER RE-RENDERING 
  // const updatedSelectedCars = [...selectedCars, carDetails];


  // let updatedSelectedCars;  // Define the variable here

  //   // Use the callback form of setState to ensure the state is updated
  // // before logging or navigating



  setSelectedCars(prevSelectedCars => {
    const updatedSelectedCars = [...prevSelectedCars, carDetails];
    console.log('Updated Selected Cars:', updatedSelectedCars);
    
    navigate('/results', {
    
      state: {
        selectedBrand,
        selectedModel,
        selectedYearOfCar: yearOfCarToUse,
        selectedFuelType: fuelTypeToUse,
        selectedOwnershipYears,
        selectedCars: updatedSelectedCars,
      }
    });
    
    return updatedSelectedCars;
  });
  

  
};





//   setSelectedCars(prevSelectedCars => {
//     const updatedSelectedCars = [...prevSelectedCars, carDetails];
//     console.log('Updated Selected Cars:', updatedSelectedCars);
    
    
    
//     return updatedSelectedCars;
//   });
  

//   navigate('/results', {
    
//     state: {
//       selectedBrand,
//       selectedModel,
//       selectedYearOfCar: yearOfCarToUse,
//       selectedFuelType: fuelTypeToUse,
//       selectedCars: updatedSelectedCars
//     }
//   });
// };


console.log('isComparisonMode:', isComparisonMode);


  return (
    <>
    <Wrapper>
      {isLoading && <Loading>Var god vänta...</Loading>}
      {!isLoading && (
        <>
          {isComparisonMode ? (
        <MainQuestion>Vilken bil skulle du vilja jämföra?</MainQuestion>
        ) : (
          <MainQuestion> Vilken bil är du intresserad av att köpa?</MainQuestion>
        )
      }
      <DropdownMenu
        label="Bilmärke: "
        options={carBrand}
        selectedOption={selectedBrand}
        onChange={setSelectedBrand}
        placeholder="Välj märke"
      />
    
      <DropdownMenu
        label="Bilmodell: "
        options={carModel}
        selectedOption={selectedModel}
        onChange={setSelectedModel}
        placeholder="Välj modell"
      />

      {selectedBrand && selectedModel && (
        <>
      {/* Render the DropdownMenu for Modellår only after Brand and Model are selected */}

        {/* To catch when there are no cars in the data set between 2016 and 2024 eg Lancia Voyager */ }
        {yearOfCar.length === 0  && (

        <WarningMessage ref={messageRef}></WarningMessage>
        )}

        {/* To catch when more than one year of car */}
        {yearOfCar.length > 0 && 
        <DropdownMenu
          label="Modellår: "
          options={yearOfCar}
          selectedOption={selectedYearOfCar}
          onChange={setSelectedYearOfCar}
          sortType="numerical"
          placeholder="Välj årtal"
          />
}

          {/* <ResultsTitle>Här kommer dina prisjämförelseresultat* för {selectedBrand} {selectedModel}</ResultsTitle> */}
          {/* <ComparisonTable aggregationResults={aggregationResults}/>
          <Disclaimer>*För att underlätta jämförelsen visas priserna i tusentals kronor</Disclaimer> */}


      {/* To catch when there is only one year and one fuel type eg Dodge Challenger 2018   */}


      {yearOfCar.length === 1 && fuelType.length === 1 && (
         <DropdownMenu
         label="Drivmedel: "
         options={fuelType}
         selectedOption={selectedFuelType}
         onChange={setSelectedFuelType}
         placeholder="Välj drivmedel"
       />    
       )
      }

      {yearOfCar.length === 1 && fuelType.length === 1 && selectedFuelType && (
        <DropdownMenu
        label="Ägande-längd: "
        options={ownershipYears}
        selectedOption={selectedOwnershipYears}
        onChange={handleOwnershipYearsChange}
        sortType="numerical"
        placeholder="Välj antal år"
      />

      )}
      {/* {shouldRenderContent && yearOfCar.length === 1 && fuelType.length !== '' && ( */}
      {/* {shouldRenderContent && yearOfCar.length !== '' && fuelType.length !== '' && ( */}
      {shouldRenderContent && yearOfCar.length === 1 && fuelType.length === 1 && selectedFuelType && selectedOwnershipYears !=='' && (  
        <>
          <Question>Beräkna värdeminskning för:</Question>
          <SelectedCar>{`${selectedBrand} ${selectedModel}`}
            <br/>{`${selectedYearOfCar}`}
            <br/>{`${selectedFuelType}`}
            <br/><br/><p>Ägandelängd: {`${selectedOwnershipYears}`} år</p>
          </SelectedCar>
          <ButtonContainer>
            <ConfirmationButton type="button" buttonText="Nu kör vi!" selectedBrand={selectedBrand} selectedModel={selectedModel} selectedYearOfCar={selectedYearOfCar} selectedFuelType={fuelType[0]} onClick={navigateToResults} />
          </ButtonContainer>
        </>
      )}  

      {/* To catch when there is only one year but multiple fuel types eg VOLVO v70 */}

      {yearOfCar.length === 1 && fuelType.length > 1 && (
        <>
          {/* <h3>Please confirm the fuel type you are interested in calculating the monthly cost for:</h3> */}
          {/* <MainQuestion>Bekräfta drivmedel: </MainQuestion>  */}
          <DropdownMenu
            label="Drivmedel: "
            options={fuelType}
            selectedOption={selectedFuelType}
            onChange={setSelectedFuelType}
            placeholder="Välj drivmedel"
          />    
        </>
      )}
     
      {yearOfCar.length === 1 && fuelType.length > 1 && selectedFuelType && (
              <DropdownMenu
              label="Ägande år: "
              options={ownershipYears}
              selectedOption={selectedOwnershipYears}
              onChange={handleOwnershipYearsChange}
              sortType="numerical"
              placeholder="Välj antal år"
            />

            )}
         
      {shouldRenderContent && yearOfCar.length === 1 && fuelType.length > 1 && selectedFuelType && selectedOwnershipYears && (
        <>
          <Question>Beräkna värdeminskning nu för:</Question>
          <SelectedCar>{`${selectedBrand} ${selectedModel}`}
          <br/>{`${selectedFuelType}`}
          <br/>{`${selectedYearOfCar}`}
          <br/><br/><p>Ägandelängd: {`${selectedOwnershipYears}`} år</p>
          </SelectedCar>
          <ButtonContainer>
            <ConfirmationButton type="button" buttonText="Nu kör vi!" selectedBrand={selectedBrand} selectedModel={selectedModel} selectedYearOfCar={selectedYearOfCar} selectedFuelType={fuelType[0]} onClick={navigateToResults} />
          </ButtonContainer>
          </> 
      )}  

      {/* To catch when there is more than one year but one fuel type eg Toyota Hilux / Porsche 911 2022 / VOLOV xc90 2024 MH*/}

      {yearOfCar.length > 1 && fuelType.length === 1 && ( 
        <>
        {/* <DropDownQuestion>Please confirm which year of car you are interested in calculating the monthly cost for:</DropDownQuestion> */}

        {/* <DropdownMenu
          label="Modellår: "
          options={yearOfCar}
          selectedOption={selectedYearOfCar}
          onChange={setSelectedYearOfCar}
          sortType="numerical" 
        /> */}
          <DropdownMenu
              label="Drivmedel: "
              options={fuelType}
              selectedOption={selectedFuelType}
              onChange={setSelectedFuelType}
              placeholder="Välj drivmedel"
          />  

            
      {yearOfCar.length > 1 && fuelType.length === 1 && selectedFuelType && (
              <DropdownMenu
              label="Ägande-längd: "
              options={ownershipYears}
              selectedOption={selectedOwnershipYears}
              onChange={handleOwnershipYearsChange}
              sortType="numerical"
              placeholder="Välj antal år"
            />

            )}

          {shouldRenderContent && selectedFuelType && selectedOwnershipYears && (
            <>
              <Question>Beräkna värdeminskning nu för:</Question>
              <SelectedCar>{`${selectedBrand} ${selectedModel}`}
              <br />{`${selectedYearOfCar}`}
              <br />{`${selectedFuelType}`}
              <br/><br/><p>Ägande-längd: {`${selectedOwnershipYears}`} år</p></SelectedCar>
              {/* <br /> */}
              {/* <p>OBS! Det går inte att välja drivmedelen för denna bil</p>               */}

              <ButtonContainer>
                <ConfirmationButton type="button" buttonText="Nu kör vi!" selectedBrand={selectedBrand} selectedModel={selectedModel} selectedYearOfCar={selectedYearOfCar} selectedFuelType={fuelType[0]} onClick={navigateToResults} />
              </ButtonContainer>

              {/* <ConfirmationButton type="button" buttonText="Confirm and view results" onClick={navigateToResults}  /> */}


              {/* <ConfirmationButton type="button" selectedBrand={selectedBrand} selectedModel={selectedModel} selectedYearOfCar={selectedYearOfCar} selectedFuelType={selectedFuelType} /> */}
            </>
          )}
        </>
      )}
            

      {/* To catch when there is more than one year and more than one fuel type eg Volvo xc60  */}
    
          {yearOfCar.length > 1 && fuelType.length > 1 && (
            <>
              {/* <h3>Please confirm which car and fuel type you are interested in calculating the monthly cost for: </h3> */}
              {/* <DropDownQuestion>Bekräfta modellår och drivmedel:</DropDownQuestion> */}
              {/* <DropdownMenu
                label="Modellår: "
                options={yearOfCar}
                selectedOption={selectedYearOfCar}
                onChange={setSelectedYearOfCar}
                sortType="numerical" 
              /> */}
      {/* NOT NEEDED NOW AS DOING A CONFIRMATION: To catch if there is only one type of fuel for each year after this eg Alfa Romeo Stelvio */}
      {/* {selectedYearOfCar !== '' && selectedYearOfCar.fuelType && selectedYearOfCar.fuelType.length >= 2 && */}
              <DropdownMenu
                label="Drivmedel: "
                options={fuelType}
                selectedOption={selectedFuelType}
                onChange={setSelectedFuelType}
                placeholder="Välj drivmedel"
                />
      
      {yearOfCar.length > 1 && fuelType.length > 1 && selectedFuelType && (
              <DropdownMenu
              label="Ägande år: "
              options={ownershipYears}
              selectedOption={selectedOwnershipYears}
              onChange={handleOwnershipYearsChange}
              sortType="numerical"
              placeholder="Välj antal år"
            />

            )}
              {shouldRenderContent && selectedFuelType && selectedOwnershipYears && (
                <>
                  <Question>Beräkna värdeminskning nu för:</Question>
                  <SelectedCar>{`${selectedBrand} ${selectedModel}`} 
                  <br/>
                  {`${selectedFuelType}`}
                  <br />
                  {`${selectedYearOfCar}`}
                  <br/><br/><p>Ägandelängd: {`${selectedOwnershipYears}`} år</p></SelectedCar>
                  {/* <ConfirmationButton type="button" selectedBrand={selectedBrand} selectedModel={selectedModel} selectedYearOfCar={selectedYearOfCar} selectedFuelType={selectedFuelType} onClick={()=> {navigateToResults()}} /> */}
                  <ButtonContainer>
                    <ConfirmationButton type="button" buttonText="Nu kör vi!" selectedBrand={selectedBrand} selectedModel={selectedModel} selectedYearOfCar={selectedYearOfCar} selectedFuelType={fuelType[0]} onClick={navigateToResults} />
                  </ButtonContainer>

                  
                  {/* THIS BELOW CAUSES ERRORS */}
                  {/* <ConfirmationButton type="button" onClick={() => {navigateToResults(selectedBrand, selectedModel, selectedYearOfCar, selectedFuelType)}} /> */}

                </>
              )}  
      
            </>
           )}
        </>
      )}
        </>
      )}
      
      </Wrapper>
    </>
  )};

