import styled from 'styled-components';
import { typography } from '../../styles/mixins';

export const MainQuestion = styled.h1`
    ${typography('h4', 'header')};
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;

    @media (min-width: 744px) {
        ${typography('h3')};
        padding-top: 2rem;
    }
`

export const ResultsTitle = styled.h2`
    ${typography('h4', 'header')};
    padding-top: 2.5rem;
    padding-bottom: 1rem;

    @media (min-width: 744px) {
        padding-top: 2rem;
    }

`

export const Disclaimer = styled.p`
    ${typography('p6', 'paragraph')};
    padding-top: 0.5rem;
    padding-bottom: 1rem;

    @media (min-width: 744px) {
        ${typography('p5')};
        padding-top: 1rem;

    }
    @media (min-width: 744px) {
        ${typography('p4')};
    }
`

export const Question = styled.h3`
    color: var(--color-neutral-4-white);
    background-color: var(--color-neutral-1-black);
    width: 100%;
    padding: 0.5rem;
    margin-top: 3.5rem;
    margin-bottom: 1.5rem;
    border-radius: 0.25rem;
    ${typography('h5', 'header')}
    font-weight: bold;
    display: none;

    @media (min-width: 744px) {
        ${typography('h4')};
        font-weight: bold;
        align-self: center;
        padding-top: 1rem;
        padding-bottom: 1rem;
    }

    @media (min-width: 1440px) {
        ${typography('h3')};
        font-weight: bold;
        padding-top: 1.375rem;
        padding-bottom: 1.375rem;
    }
    
`

export const WarningMessage = styled.p`
    ${typography('p5', 'paragraph')}
    color: var(--color-error);
    padding-top: 1rem;
    text-align: center;

    @media (min-width: 744px) {
        ${typography('p4', 'paragraph')}
        }

        @media (min-width: 1440px) {
        ${typography('p3', 'paragraph')}
        }
`

export const SelectedCar = styled.h5`
       ${typography('h6', 'header')}
       font-weight: bold;
       color: var(--color-neutral-1-black);
       /* border: 0.1rem solid var(--color-neutral-3-light-grey); */
       background-color: var(--color-neutral-4-white);
       /* color: var(--color-neutral-4-white);
       background-color: var(--color-neutral-2-grey); */
       padding: 0.5rem;
       border: 0.02rem solid var(--color-neutral-2-grey);
       border-radius: 0.6rem;
       /* color: var(--color-neutral-4-white);
       background-color: var(--color-neutral-2-grey);  */
       box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5);
       display: none;

       @media (min-width: 744px) {
        ${typography('h5', 'header')}
        font-weight: bold;
         min-width: 23rem;
        }

       @media (min-width: 1440px) {
        ${typography('h4', 'header')}
         min-width: 25rem;
        }

`

export const ButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin-top: 2rem;
    margin-bottom: 1rem;
   
`