import styled from 'styled-components';
import { typography } from '../../styles/mixins';

// export const BrandName = styled.h1`
//     color: var(--color-neutral-1-black);
//     ${typography('h1', 'header')};
//     margin: 0;
// `

export const TitleQuestion = styled.h1`
    /* color: var(--color-neutral-4-white);
    background-color: var(--color-neutral-2-grey); */
    color: var(--color-neutral-2-grey);
    /* display: flex; */
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    max-width: 100%;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    ${typography('h3', 'header')};

    /* span {
        color: var(--color-primary);
        font-weight: bold;
        display: inline;
        white-space: nowrap;
    } */

    @media (min-width: 744px) {
        ${typography('h2')};
        padding-top: 1rem;
        padding-bottom: 1rem;
    }

     @media (min-width: 1440px) {
        padding-top: 0.5rem;

    
    } 
`

export const Information = styled.p`
    color: var(--color-neutral-1-black);
    text-align: center;
    ${typography('p3', 'paragraph')};
    margin-bottom: 1.5rem;

    span {
        color: var(--color-primary);
        font-weight: bold;
    }
     @media (min-width: 744px) {
        ${typography('p3')};
    }

    @media (min-width: 1440px) {
        ${typography('p2')};
    }
`

export const TitleList = styled.h2`
    ${typography('p3')};
    font-weight: bold;
    text-align: center;
    margin-top: 1.5rem;

    @media (min-width: 744px) {
        ${typography('p2')};
        font-weight: bold;

    }

    /* @media (min-width: 1440px) {
        ${typography('p1')};
        font-weight: bold;

    } */
`

export const ListContainer = styled.ul`
    padding-top: 1.375rem;
    padding-bottom: 1.375rem;

    @media (min-width: 744px) {
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;
    }

    @media (min-width: 1440px) {
        display: flex;
        flex-direction: row;
        gap: 2rem;
        padding-top: 3rem;
        padding-bottom: 3rem;
    }
`
export const List = styled.li`
    color: var(--color-neutral-1-black);
    list-style-type: none;
    ${typography('p4')};
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 0.5rem;
    gap: 0.5rem;

    @media (min-width: 744px) {
        ${typography('p3')};
        gap: 0.7rem;
        padding-bottom: 0.875rem;
    }

    @media (min-width: 1440px) {
        ${typography('p2')};
        gap: 0.8rem;
        padding-bottom: 1.125rem;
    }
`
  

export const StyledCarLottie = styled.div`

    --height: 200px;
    --width: 300px;
    height: var(--height);
    width: var(--width);
  
/*   
  @media (min-width: 744px) {
  
      --height: 300px;
      --width: 400px;
      height: var(--height);
      width: var(--width);
    
  }
  
  @media (min-width: 1440px) {
      --height: 250px;
      --width: 450px;
      height: var(--height);
      width: var(--width);
  } */

  `