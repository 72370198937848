import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Graph } from '../../assets/Graph.svg';
import { ReactComponent as Umbrella } from '../../assets/Umbrella.svg';
import { ReactComponent as Money } from '../../assets/Money.svg';
import { Icon } from '../../ui/Icon/Icon';
import { Wrapper } from '../Wrapper/Wrapper';
import { BrandName, TitleQuestion, Information, TitleList, ListContainer, List, StyledCarLottie } from './WelcomePage.styles';
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import Car from '../../lotties/CarColoured.json'
import { Button } from '../../ui/Button/Button'; 


export const WelcomePage = () => {
    const navigate = useNavigate();

    const goToComparisonPage = () => {
        navigate("/comparison");
    }


    return (
        <>
            <Wrapper isWelcomePage>
                {/* <BrandName>Min Bilkostnad</BrandName> */}
                {/* <TitleQuestion>Visste du att den största kostnaden för din bil oftast är <span>värdeminskningen?</span></TitleQuestion> */}

                <TitleQuestion>Visste du att <span style={{ color: 'var(--color-primary)', fontWeight: 'bold', whiteSpace: 'nowrap' }}>värdeminskningen</span> oftast är den största kostnaden för din bil?</TitleQuestion>
                <Information>Vi hjälper dig att <span>kostnadsfritt</span> uppskatta din bilkostnad på mindre än <span>1 minut.</span></Information>
                <StyledCarLottie>
                    <Player
                            autoplay
                            loop
                            src={Car}
                            speed={0.4}
                            // style={{ height: '10.8125rem', width: '17.0625rem' }}
                            style={{ 'height': 'var(--height)', 'width': 'var(--width'}}
                            // style={{ height: '7.8125rem', width: '14.0625rem' }}

                    >
                    <Controls visible={false} buttons={['play', 'repeat', 'frame', 'debug']} />
                    </Player>
                </StyledCarLottie>
            
                <TitleList>Vi beräknar kostnaden baserat på</TitleList>
                <ListContainer>
                    <List><Icon as={Graph}/> Värdeminskning</List>
                    <List><Icon as={Umbrella}/> Försäkringskostnad</List>
                    <List><Icon as={Money}/> Lånekostnad</List>
                </ListContainer>
                <Button type="button" onClick={goToComparisonPage}>Beräkna min bilkostnad</Button>
            </Wrapper>
        </>
    )
}