import React from 'react';
import { useComparisonMode } from '../../context/ComparisonModeContext';
import { Button } from '../../ui/Button/Button'



export const SummaryButton = ({
  onClick,
  selectedBrand,
  selectedModel,
  getFuelType,
  getYearOfCar,
  monthlyDepreciation,
  higherPrice,
  lowerPrice,
  userWantsLoan,
  calculatedLoanCost,
  userWantsInsurance,
  insuranceCost,
  isFirstVisitToResults,
  setIsFirstVisitToResults,
  children, 
  primary,
  secondary
}) => {

  console.log('secondary prop in SummaryButton:', secondary);


  const { selectedCars, setSelectedCars, selectedOwnershipYears } = useComparisonMode();

  const handleSummaryClick = () => {
    console.log('Logging from SummaryButton handleSummaryClick');
    console.log("getFuelType in SummaryButton:", getFuelType);
    console.log("getYearOfCar in SummaryButton:", getYearOfCar);
    onClick({
      selectedBrand,
      selectedModel,
    //   fuelType: getFuelType(),//C
      getYearOfCar: getYearOfCar,//? WORKS
    // fuelType: getFuelType,
    //   getFuelType: getFuelType, WORKS
    //   getFuelType: fuelType,
      getFuelType, //WORKS
      monthlyDepreciation,
      higherPrice,
      lowerPrice,
      userWantsLoan,
      calculatedLoanCost,
      userWantsInsurance,
      insuranceCost,
      isFirstVisitToResults,
      setIsFirstVisitToResults,
      selectedCars,
      setSelectedCars,
      selectedOwnershipYears,
    });
    console.log("getFuelType in onClick in SummaryButton:", getFuelType);
    console.log("setIsFirstVisitToResults present in SummaryButton", setIsFirstVisitToResults)

  };

  return (
    <Button type="button" onClick={handleSummaryClick} secondary={secondary} primary={primary}>
      {children}
    
    </Button>
  );
};
