import React from 'react';
import { Menu, Brand, StyledLogoLink, CompanyName, StyledLogo, NavList, NavListMobile, NavListNonMobile, NavItem, NavLink } from './NavBar.styles';
import logo from '../../assets/CarLogo.svg';
import { HamburgerMenu } from '../../ui/HamburgerMenu/HamburgerMenu.js';

export const NavBar = () => {

    const goToWelcomePage = () => {
      };

    return (
        <>
        <Menu>
            <Brand>
                <StyledLogoLink to="/" onClick={goToWelcomePage}>
                    <StyledLogo src={logo} alt="Logo" />
                    <CompanyName>Min Bilkostnad</CompanyName>  
                </StyledLogoLink>   
            </Brand>
            <NavListMobile>
                <HamburgerMenu />
            </NavListMobile>
            <NavListNonMobile>
                <NavList>
                    <NavItem><NavLink href="#welcome">Home</NavLink></NavItem>
                    <NavItem><NavLink href="#about">About Us</NavLink></NavItem>
                    <NavItem><NavLink a href="#blog">Blog</NavLink></NavItem>
                    <NavItem><NavLink a href="#table">Comparison</NavLink></NavItem>
                </NavList>
            </NavListNonMobile>
        </Menu>         
        </>
     
    )
}