import React from 'react';
import { WrapperContainer, WrapperWelcome } from './Wrapper.styles'

export const Wrapper = ({children, isWelcomePage }) => {
    
    if (isWelcomePage) {
        return <WrapperWelcome>{children}</WrapperWelcome>
    } else {
        return <WrapperContainer>{children}</WrapperContainer>;
    }
}

