import styled from 'styled-components';
import { typography } from '../../styles/mixins';

export const InsuranceChoice = styled.h3`
    color: var(--color-neutral-1-black);
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    margin-top: 1rem;
    ${typography('h6', 'paragraph')};
    font-weight: bold;
    background-color: var(--color-neutral-3-light-grey);


    @media (min-width: 744px) {
        ${typography('h5')};
        font-weight: bold;
        text-align: center;
        padding-top: 1rem;
        padding-bottom: 1rem;
    }

    @media (min-width: 1440px) {
        ${typography('h4')};
        font-weight: bold;
        padding-top: 1.375rem;
        padding-bottom: 1.375rem;
    }

`

export const ButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
   
`
export const Question = styled.h3`
    /* color: var(--color-neutral-1-black); */
    color: var(--color-neutral-4-white);
    background-color: var(--color-neutral-1-black);
    /* max-width: 100%; */
    width: 100%;
    padding: 0.5rem;
    margin-top: 3.5rem;
    margin-bottom: 0.5rem;
    border-radius: 0.25rem;
    ${typography('h5', 'header')}
    font-weight: bold;

    @media (min-width: 744px) {
        ${typography('h4')};
        font-weight: bold;
        /* align-self: center; */
        /* max-width: 700px; */
        
    }

    @media (min-width: 1440px) {
        ${typography('h3')};
        font-weight: bold;
    }
`

// @media (min-width: 744px) {
//     min-width: 700px;
// }

// @media (min-width: 1440px) {
//     min-width: 1050px;
// }


export const LoadingSign = styled.p`
    display: none;
`