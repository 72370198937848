import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { handleSummaryClickLogic } from '../../utils/utils.js';
import { DropdownMenu } from '../../ui/DropdownMenu/DropdownMenu';
import { LoanCost } from '../LoanCost/LoanCost';
import { SummaryButton } from '../SummaryButton/SummaryButton';
import { Button } from '../../ui/Button/Button';
import { InsuranceChoice, ButtonContainer, Question, LoadingSign } from './InsuranceCost.styles';
import { LoanCostInfo } from '../LoanCost/LoanCost.styles';
import { Wrapper } from '../Wrapper/Wrapper';



export const InsuranceCost = ({ 
    selectedBrand, 
    selectedModel, 
    getFuelType, 
    getYearOfCar, 
    monthlyDepreciation, 
    higherPrice,
    lowerPrice,
    userWantsInsurance, 
    isFirstVisitToResults, 
    setIsFirstVisitToResults, 
    selectedCars, 
    setSelectedCars,
    selectedYearPrice,
    selectedInsuranceType,
    selectedOwnershipYears }) => {

    const [insuranceType, setInsuranceType] = useState(["Halv", "Hel"]); // "Hel: 500SEK", "Halv: 750SEK"
    // const [selectedInsuranceType, setSelectedInsuranceType] = useState(null);
    const [showNextPart, setShowNextPart] = useState(false);
    const [insuranceCost, setInsuranceCost] = useState(null);
    const [userWantsLoan, setUserWantsLoan] = useState(false);
    const [insuranceChoiceDisplayed, setInsuranceChoiceDisplayed] = useState(false);
    const navigate = useNavigate();

  console.log('Queries for fetch:', selectedBrand, selectedModel, getFuelType(), getYearOfCar())
  
    
    useEffect(() => {
        const fetchInsuranceType = async () => {
            try {
                const url = `https://minbilkostnad-backend.onrender.com/insurance?selectedBrand=${encodeURIComponent(selectedBrand)}&selectedModel=${encodeURIComponent(selectedModel)}&selectedYearOfCar=${encodeURIComponent(getYearOfCar())}&selectedFuelType=${encodeURIComponent(getFuelType())}&selectedInsuranceType=${encodeURIComponent(selectedInsuranceType)}`;
                console.log('Request URL:', url);
    
                const response = await fetch(url);
    
                if (!response.ok) {
                    throw new Error(`Failed to fetch insurance types. Status: ${response.status}`);
                }
    
                let data;
                try {
                    data = await response.json();
                } catch (error) {
                    console.error('Error parsing JSON response:', error);
                    // Handle the non-JSON response here, if needed
                }
    
                console.log('Response from server:', data);
                console.log('Content-Type:', response.headers.get('Content-Type'));
    
                if (data && data.success) {
                   const insuranceCost = data.response;
    
                    if (insuranceCost !== null && insuranceCost !== undefined) {
                      setInsuranceCost(insuranceCost);
                      setShowNextPart(true);
                    } else {
                      console.error('No insurance price data found');
                      setShowNextPart(false);
                    }
                    } else {
                      console.error(data ? data.message : 'Unknown error');
                      setShowNextPart(false);
                    } 
                  }catch (error) {
                      console.error('Error fetching insurance types', error);
                    
    
                // Log more information about the response
                if (error.response) {
                    console.error('Response status:', error.response.status);
                    console.error('Response text:', await error.response.text());
                    console.error('Response object:', error.response);
                }
            } finally {
                console.log('This log should always appear');
            }
        };
    
        fetchInsuranceType();
    }, [selectedBrand, selectedModel, getYearOfCar, getFuelType, selectedInsuranceType]);
    

    useEffect(() => {
      if (selectedInsuranceType !== null) {
        setInsuranceChoiceDisplayed(true);
      } 
    }, [selectedInsuranceType]);
    

    const handleSummaryClick = () => {
      const newData = {
        selectedBrand,
        selectedModel, 
      //   fuelType: selectedFuelType,
        fuelType: getFuelType(), //C
        yearOfCar: getYearOfCar(), //C
        monthlyDepreciation,
        higherPrice,
        lowerPrice,
        userWantsLoan,
        userWantsInsurance,
        insuranceCost,
      //   setIsFirstVisitToResults,
        isFirstVisitToResults: setIsFirstVisitToResults(),
        selectedOwnershipYears
      };
      handleSummaryClickLogic(navigate, setSelectedCars, selectedCars, newData, newData.insuranceCost);

    }

    return (
        <>
        <Wrapper>
          {/* <h2>Please select which insurance type you would like:</h2> */}
          {/* <ButtonContainer>
                    <Button 
                        type="button" 
                        onClick={() => setSelectedInsuranceType("Halv")}
                        active={selectedInsuranceType === "Halv"}
                    >
                        Halv Försäkring
                    </Button>
                    <Button 
                        type="button" 
                        onClick={() => setSelectedInsuranceType("Hel")}
                        active={selectedInsuranceType === "Hel"}
                    >
                        Hel Försäkring
                    </Button>
                </ButtonContainer> */}
          {/* <DropdownMenu 
           label="Typ: "
           options={insuranceType}

           selectedOption={selectedInsuranceType}
           onChange={(newInsuranceType) => {
            console.log('Selected Insurance Type:', newInsuranceType);
            setSelectedInsuranceType(newInsuranceType);
          }}
          /> */}
           </Wrapper>
        {showNextPart && (
        <div>
          {selectedInsuranceType !== null ? (
            <InsuranceChoice>+ {selectedInsuranceType} försäkring ({insuranceCost} SEK / månad)</InsuranceChoice>
          ) : (
            <LoadingSign>Waiting for choice...</LoadingSign>
          )}

          {insuranceChoiceDisplayed && (
            <>
              <Question>Lägg till billån?</Question>
              <LoanCostInfo>Vi kan hjälpa dig att uppskatta de månatliga betalningarna för ett <b>{`${selectedOwnershipYears}`}-årigt</b> annuitetslån med en fast ränta på 7,95%.</LoanCostInfo>
              <ButtonContainer>
                <Button type="button" onClick={() => setUserWantsLoan(true)}>Gärna!</Button>
                {/* <button type="button" onClick={(navigateToComparison) => setUserWantsLoan(false)}>No, help me compare another car!</button>     */}
                <SummaryButton type="button" onClick={handleSummaryClick}  
                  selectedBrand={selectedBrand}
                  selectedModel={selectedModel}
                  getFuelType={getFuelType()}
                  getYearOfCar={getYearOfCar()}
                  monthlyDepreciation={monthlyDepreciation}
                  higherPrice={higherPrice}
                  lowerPrice={lowerPrice}
                  userWantsLoan={userWantsLoan}
                  insuranceCost={insuranceCost}                  
                  isFirstVisitToResults={isFirstVisitToResults}
                  setIsFirstVisitToResults={setIsFirstVisitToResults}
                  selectedCars={selectedCars}
                  setSelectedCars={setSelectedCars}
                  selectedOwnershipYears={selectedOwnershipYears}
                  secondary={"secondary"}
                  >Nej, jag är klar!</SummaryButton>    
                {/* NEED  to handle YES TO INSURANCE AND NO TO LOAN. NEED TO PASS ON INSURNACE TO SUMMARY PAGE WITH NO LOAN */}
              </ButtonContainer>
                        
              {userWantsLoan === true && (
                // YES TO INSURANCE, YES TO LOAN
                  <LoanCost 
                    selectedBrand={selectedBrand} 
                    selectedModel={selectedModel} 
                    getFuelType={getFuelType}
                    getYearOfCar={getYearOfCar}
                    monthlyDepreciation={monthlyDepreciation} 
                    higherPrice={higherPrice}
                    lowerPrice={lowerPrice}
                    userWantsLoan={userWantsLoan}
                    userWantsInsurance={userWantsInsurance}
                    insuranceCost={insuranceCost}
                    isFirstVisitToResults={isFirstVisitToResults}
                    setIsFirstVisitToResults={setIsFirstVisitToResults}
                    selectedCars={selectedCars}
                    setSelectedCars={setSelectedCars}
                    selectedYearPrice={selectedYearPrice}
                    selectedOwnershipYears={selectedOwnershipYears}
                    />
              )}     
             
                      
            </>

          )}
      
        </div>
      )}
     
        </>
       
    )
}